const initialState = {
    searchResults: [],
    term: '',
    searchEmpty: false
}

const SEARCH_CONTENT = "SEARCH_CONTENT";
const SEARCH_TERM = "SEARCH_TERM";
const SEARCH_EMPTY = "SEARCH_EMPTY";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SEARCH_CONTENT: {
            return {...state,
                searchResults: action.payload}
        }
        case SEARCH_TERM: {
            return {...state,
                term: action.payload}
        }
        case SEARCH_EMPTY: {
            return {...state,
                searchEmpty: action.payload}
        }
        default:
            return state;
    }
}