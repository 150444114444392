const initialState = {
    yourConditionItems: []
}

const LOAD_CONDITION_ITEMS = "LOAD_CONDITION_ITEMS";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_CONDITION_ITEMS: {
            return {...state,
                yourConditionItems: action.payload}
        }
        default:
            return state;
    }
    
}