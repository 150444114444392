import React, {useEffect, useState, useLayoutEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CSSTransition } from 'react-transition-group'

// redux action
import { fetchLanguageClinical } from '../redux/actions/searchPracticeAction'

import SeoMetaTags from '../components/globalComponents/SeoMetaTags'

// css
import './FindAPhysio.sass'
// redux actions
import { hideFullscreenFeatured } from '../redux/actions/fullscreenFeaturedAction'
import SearchResult from '../components/findaphysio/SearchResult'
import SearchOptions from '../components/findaphysio/SearchOptions'
import DigitalAds from '../components/globalComponents/DigitalAds' 
export default function FindAPhysio(){
    const [languages, setLanguages] = useState([])
    const [clinicalAreas, setClinicalAreas] = useState([])
    const [specialistAreas, setSpecialistAreas] = useState([])
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(hideFullscreenFeatured());
        }, [dispatch])
    useEffect(() => {
        dispatch(fetchLanguageClinical)
            .then(response => {
                setLanguages(response.language)
                setClinicalAreas(response.clinicalArea)
                setSpecialistAreas(response.SpecialCode)
            })
            .catch(error => {
                console.log(error)
            })
    }, [dispatch])

    useLayoutEffect(() => {
        function updateScreenWidth() {
          setScreenWidth(window.innerWidth)
        }
        window.addEventListener('resize', updateScreenWidth)
        updateScreenWidth()
    }, [])

	const metaTitle = "Find a physio - Choose physio | Australian Physiotherapy Association"
    const metaDescription = "Find a physio. Your GP's most recommended health professional. Choose physio to help you recover from injury, reduce pain and stiffness, increase mobility and prevent further injury."

    const isResultsVisible = useSelector(state => state.searchPractice.isResultsVisible)
    const isSearchOptionsVisible = useSelector(state => state.searchPractice.isSearchOptionsVisible)

	return(
		<div id="page_content" className="inner_gap find-a-physio">
            <SeoMetaTags 
                title={metaTitle} 
                description={metaDescription} 
                twitterImg="" 
                ogImg="" />

            <CSSTransition 
                in={isSearchOptionsVisible} appear={isSearchOptionsVisible} 
                classNames="fastFade" timeout={300} unmountOnExit >
                <SearchOptions 
                    languages={languages} 
                    clinicalAreas={clinicalAreas} 
                    specialistAreas={specialistAreas} 
                />
            </CSSTransition>

            <CSSTransition 
                in={isResultsVisible} appear={isResultsVisible} 
                classNames="fastFade" timeout={300} unmountOnExit >
                <SearchResult 
                    languages={languages} 
                    clinicalAreas={clinicalAreas} 
                    specialistAreas={specialistAreas} 
                    screenWidth={screenWidth}
                />
            </CSSTransition>
            { isSearchOptionsVisible && 
                 <DigitalAds />
            }
           
        </div>
	)
}