import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SeoMetaTags from '../components/globalComponents/SeoMetaTags'
import { Link } from 'react-router-dom'

// redux action
import { contentSearch, setSearchTerm } from '../redux/actions/contentSearchAction'

// search elements
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'

// icons
import SearchIcon from '@material-ui/icons/Search'

// css
import './Search.sass'

export default function Search() {
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [searchPage, setSearchPage] = useState({
        selected: 1,
        current: 1,
        last: '',
        range: [],
        itemPerPage: 5
    })

    const dispatch = useDispatch()

    // update search term
    const handleSearchTerm = (term) => {
        dispatch(setSearchTerm(term))
    }

    // search for results with set term
    const handleSearch = async () => {
        setIsLoading(true)

        dispatch(contentSearch(storeSearchTerm))
        .then(response => {
            console.log(response)
            setIsLoading(false)
        })
        .catch(error => {
            console.log(error)
            setIsError(true)
        })
    }

    // search on key enter/submit
    const handleSearchKeyEnter = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        dispatch(contentSearch(storeSearchTerm))
        .then(response => {
            //console.log(response)
            setIsLoading(false)
        })
        .catch(error => {
            //console.log(error)
            setIsError(true)
        })
    }

    const handlePagination = (selectedPage) =>{
        setSearchPage({
            ...searchPage,
            current: selectedPage
        })
    }

    // get search data from store
    const storeSearchTerm = useSelector(state => state.contentSearch.term)
    const searchResults = useSelector(state => state.contentSearch.searchResults)
    const searchEmpty = useSelector(state => state.contentSearch.searchEmpty)

    useEffect(() => {
        const totalPages = Math.ceil(searchResults.length/searchPage.itemPerPage)
        let page = 1
        const tempRange = []
        while (page <= totalPages) {
            tempRange.push({id: page})
            page += 1
        }
        setSearchPage({
            current: 1,
            last: totalPages,
            range: tempRange,
            itemPerPage: 5
        })
    }, [ searchResults, searchPage.itemPerPage ])

    // render search results
    const SearchResult = searchResults
    .slice((searchPage.current - 1)*searchPage.itemPerPage,searchPage.current*searchPage.itemPerPage)
    .map(result => {
        const { nid, title, body, url } = result

        let maxWord = 20
        if( window.innerWidth < 570 ){
            maxWord = 20
        } else {
            maxWord = 40
        }
        const wordCount = body.trim().split(' ').length
        let excerp = body.split(' ').slice(0,maxWord).join(' ')
        if ( wordCount > maxWord ){
            excerp = excerp + '...'
        }

        return (
            <div className="result_item" key={nid}>
                <span className="result_item_title">{title.toLowerCase()}</span>
                { excerp !== '' &&
                    <span className="result_item_body">{excerp}</span>
                }
                <Link to={url} className="result_item_link">title</Link>
            </div>
        )
    })

    // render pagination
    const searchPagination = searchPage.range
    .map(page => {
        let current = false
        if ( page.id === searchPage.current ) {
            current = true
        }
        return (
            <li className={ current ? "page_item current" : "page_item"} 
                key={page.id} 
                onClick={ () => { handlePagination(page.id) } } >
                    <span>{page.id}</span></li>
        )
    })

    const metaTitle = 'Search - Choose physio | Australian Physiotherapy Association'
    const metaDescription = 'Not sure where to find more information? Use our search tool as a quick start'
    
    return (
        <div id="page_content" class="search">
            <SeoMetaTags 
                title={metaTitle} 
                description={metaDescription}
                />

            <div className="site_container footer_separator_active">
                <div id="search-wrapper">
                    { !isError &&
                        <form className="search_form" onSubmit={ e => handleSearchKeyEnter(e) } >
                            <TextField
                                className="search-field"
                                variant="outlined"
                                type='text'
                                placeholder='Search...'
                                value={storeSearchTerm}
                                onChange={ e => handleSearchTerm(e.target.value) }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                className={isLoading ? 'is_loading' : ''}
                                                edge="end"
                                                aria-label="search content"
                                                onClick={() => { if (!isLoading){ handleSearch() }}} >
                                                <SearchIcon />
                                                { /* isLoading ? <CircularProgress className="spinning_progress" size={30} /> : <SearchIcon /> */}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            { (!isLoading && !searchEmpty) &&
                                <div className="search_result">
                                    {SearchResult}

                                    { searchPage.range.length > 1 &&
                                        <div className="search_pagination">
                                            <ul className="pagination_inner">
                                                {searchPagination}
                                                <li className="last">Last</li>
                                            </ul>
                                            <span className="fade_overlay"></span>
                                        </div>
                                    }
                                </div>
                            }
                            { searchEmpty &&
                                <div className="empty_search">
                                    <span className="empty_search_message">No result found! Please try another search term.</span>
                                </div>
                            }
                        </form>
                    }
                    { isError &&
                        <div className="search_error">
                            <h3>Oops! Our search is currently having a technical issue.<br />
                                Please try again later.
                            </h3>
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}