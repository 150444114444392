import axios from 'axios'
import { configPara } from '../../components/globalComponents/APIUrlConfig'
const LOAD_All = "LOAD_All";
const apiUrl = configPara.url.concat('/whatisphysio_rest_api');

export const fetchData = (payload) => {
    return {
        type: LOAD_All,
        payload
    }
};

export const fetchAllData = () => {
    return (dispatch) => {
        axios.get(apiUrl, {auth: configPara.auth})
            .then(response => {
                dispatch(fetchData(response.data))
            })
            .catch(error => {
                throw(error);
            });
    };
};