import React from 'react'

// import child components
import LanguageSelectionField from './LanguageSelectionField'
import ClinicalSelectionField from './ClinicalSelectionField'

import { InputAdornment, TextField } from '@material-ui/core'

//icons
import { Search } from '@material-ui/icons'

export default function SearchByPhysio(props) {
    return (
        <div className="form_row">
            <TextField
                id="input_physio"
                className="input_physio"
                variant="outlined"
                label="Name of physio"
                value={props.inputPhysio}
                onChange={e => props.handleInputPhysio(e.target.value) }
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Search />
                        </InputAdornment>
                    ),
                }}
            />
             <ClinicalSelectionField 
                ClinicalAreaRef={props.clinicalAreaRef}
                selectedClinicalArea={props.selectedClinicalArea}
                handleSelectedClinicalArea={props.handleSelectedClinicalArea}
                clinicalAreas={props.clinicalAreas} />
            <LanguageSelectionField 
                languageRef={props.languageRef}
                selectedLanguage={props.selectedLanguage}
                handleSelectedLanguage={props.handleSelectedLanguage}
                languages={props.languages} />
            
           
        </div>
    )
}