import React from 'react'

// icons
import {PhoneInTalk, Email, Web, Room} from '@material-ui/icons'

export default function ContactInfo(props) {
    const {Phone, Website, Lat, Lon} = props
    return (
        <div className="contact_info">
            { Phone !== null && Phone !== "" &&
                <a className="phone" href={`tel:${Phone}`}>
                    <span className="icon"><PhoneInTalk /></span> 
                    {Phone}
                </a>
            }
            { props.Email !== null && props.Email !== "" &&
                <a className="email" href={`mailto:${props.Email}`}>
                    <span className="icon"><Email /></span>
                    {props.Email}
                </a>
            }
            { Website !== null && Website !== "" &&
                <a className="website" 
                href={'http://'+Website} 
                target="_blank" rel="noopener noreferrer">
                    <span className="icon"><Web /> </span>
                    {Website}
                </a>
            }
            <a className="map" 
                href={`https://maps.google.com/?saddr=Current+Location&daddr=${Lat},${Lon}`}
                target="_blank" rel="noopener noreferrer">
                    <span className="icon"><Room /></span> 
                    Get directions
            </a>
        </div>
    )
}
