const initialState = {
    data: []
}

const LOAD_All = "LOAD_All";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_All: {
            return {...state,
                data: action.payload}
        }
        default:
            return state;
    }
}