import React, {useState, useLayoutEffect} from 'react'
import ReactDOM from 'react-dom'

import { FormControl, InputLabel, Select, OutlinedInput } from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

export default function SearchSpecialistField(props) {
    const [specialistLabelWidth, setSpecialistLabelWidth] = useState(0)

    useLayoutEffect(() => {
        setSpecialistLabelWidth(ReactDOM.findDOMNode(props.SpecialistAreaRef.current).offsetWidth)
    }, [props.SpecialistAreaRef])
    const specialistAreaList = props.specialistAreas.map(specialist => 
        <option value={specialist.ID} key={specialist.ID}>{specialist.Name}</option>
    )

    return (
        <FormControl variant="outlined" className="input_clinical_area">
            <InputLabel htmlFor="input_specialist_area" 
                ref={props.SpecialistAreaRef} >
                    Select Titled/Specialist Area of Practice
            </InputLabel>
            <Select
                native
                IconComponent = {ExpandMoreIcon}
                value={props.selectedSpecialistArea}
                onChange={e => props.handleSelectedSpecialistArea(e.target.value) }
                input={
                    <OutlinedInput name="input-specialist-area" 
                            labelWidth={specialistLabelWidth} 
                            id="input_specialist_area" />
                }
            >
                <option value="" />
                {specialistAreaList}
            </Select>
        </FormControl>
    )
}