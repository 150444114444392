import axios from 'axios'
import { configPara } from '../../components/globalComponents/APIUrlConfig'
const LOAD_MAIN_GRID = "LOAD_MAIN_GRID";
const apiUrl = configPara.url.concat('/homepageview');

export const fetchGrids = (payload) => {
    return {
        type: LOAD_MAIN_GRID,
        payload
    }
};

export const fetchAllGrids = () => {
    return (dispatch) => {
        axios.get(apiUrl, {auth: configPara.auth})
            .then(response => {
                dispatch(fetchGrids(response.data))
            })
            .catch(error => {
                throw(error);
            });
    };
};