import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Player } from 'video-react'
import { CSSTransition } from 'react-transition-group'

//video
// import backgroundVideo from './video/APA_NARRATIVE_004_min.mp4'

// CSS
import './FullscreenFeatured.sass'

// bootstrap elements
import { ButtonGroup, Button } from 'react-bootstrap'

// icons
//import Home from '@material-ui/icons/Home'
import { ReactComponent as HomeIcon} from './images/PHYSIO_CAN_TREAT_30px.svg'
import { ReactComponent as WhatIsPhysioIcon} from './images/WHAT_IS_PHYSIO_30px.svg'
import { ReactComponent as FAPIcon} from './images/FIND_A_PHYSIO_30px.svg'
import { ReactComponent as ChevronDown } from './images/chevron-down.svg'
import { ReactComponent as Search } from './images/search.svg'

// images
import logo from '../layout/images/APA_H_REV_212x76.png'
import videoPlaceHolder from './images/keyframe_960x530.jpg'

// redux actions
import { hideFullscreenFeatured } from '../../redux/actions/fullscreenFeaturedAction'
import { contentSearch } from '../../redux/actions/contentSearchAction'

class FullscreenFeatured extends Component{
    constructor(props) {
        super(props);

        this.state = {
            videoBackgroundImage: true,
            videoLoaded: false,
            searchFilled: false,
            searchValue: ''
        }

        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    }
 
    componentDidMount() {
        // get Background Video state and disable fullscreen & pause on click
        if (window.innerWidth > 479){
            this.refs.player.subscribeToStateChange(this.handleStateChange.bind(this));
            this.refs.player.actions.toggleFullscreen=()=>{return};
            this.refs.player.actions.pause=()=>{return};
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // initiate Background Video and hide poster image
        if(window.innerWidth > 479){
            const { player } = this.refs.player.getState();
            if( window.innerWidth > 479 && !this.state.videoLoaded && player.readyState === 4 ) {
                this.setState({ videoBackgroundImage: false, videoLoaded: true })
            }
        }
        
    }
    
    handleStateChange(state, prevState) {
        // copy player state to this component's state
        this.setState({
            player: state
        }); 
    }

    handleDismiss() {
        this.props.dispatch(hideFullscreenFeatured())
    }

    handleSearchChange(event) {
        this.setState({ searchValue: event.target.value })
        if( event.target.value !== '' ) {
            this.setState({ searchFilled: true })
        } else {
            this.setState({ searchFilled: false })
        }
    }

    handleSearchSubmit(event) {
        event.preventDefault();
        this.props.dispatch(contentSearch(this.state.searchValue))
        this.props.history.push('/search');
    }

    render() {

        const { maintenanceMsg } = this.props;

        const videoBackgroundImage = this.state.videoBackgroundImage;
        const searchFilled = this.state.searchFilled;

        return (
            <div id="fullscreen_featured">
                <div className="background_video">
                    {window.innerWidth > 479 &&
                        <Player ref="player" autoPlay={true} muted={true} fluid={false} 
                            height="100%" aspectRatio="1:1" loop={true}
                            src="video/landing_page_background.mp4" /> 
                    }
                    <CSSTransition 
                        in={videoBackgroundImage} appear={videoBackgroundImage} 
                        classNames="fastFade" timeout={300} unmountOnExit >
                            <div className="video_poster" style={{backgroundImage: `url(${videoPlaceHolder})`}} />
                    </CSSTransition>
                </div>

                <div className="site_container inner_wrapper">
                    <div className="logo">
                        <img src={logo} alt="Choose Physio Logo"/>
                    </div>
                    <div className="content_box">
                   
                        <h1 className="title">Choose physio</h1>
                        <h3 className="subtitle">{maintenanceMsg ? maintenanceMsg : <>Make your best move yet -<br/> find a physio near you today.</>}</h3>
                        {!maintenanceMsg && (<>
                        <div className="buttons_wrapper">
                            <ButtonGroup size="lg">
                                <Button onClick={this.handleDismiss} className="no_gap" ><HomeIcon /> Physio can treat..</Button>
                                <Button onClick={this.handleDismiss} className="no_gap" >
                                    <Link to={"/what-is-physio"}>
                                    <WhatIsPhysioIcon />What is physio?
                                    </Link>
                                </Button>
                                
                                <Button onClick={this.handleDismiss} className="no_gap" >
                                    <Link to={"/find-a-physio"}>
                                    <FAPIcon />Find a physio</Link>
                                </Button> 
                                
                            </ButtonGroup>
                        </div>
                        <form className={searchFilled ? 'search_box filled' : 'search_box'} onSubmit={this.handleSearchSubmit} >
                            <input type="text" id="search_condition" name="search_condition" 
                                value={this.state.searchValue}
                                onChange={this.handleSearchChange} 
                                onKeyDown={this.handleSearchKeyEnter} />
                            <span className="placeholder">Or search your condition here</span>
                            <Search />
                        </form>
                        <span className="fullscreen_featured_dismiss" onClick={this.handleDismiss}>
                            <ChevronDown />
                        </span>
                        </>)}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect()(withRouter(FullscreenFeatured));