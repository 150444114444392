import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
// components
import BackToPrevBtn from '../components/globalComponents/BackToPrevBtn'
import SeoMetaTags from '../components/globalComponents/SeoMetaTags'
import { hideFullscreenFeatured } from '../redux/actions/fullscreenFeaturedAction'
// images
import backImg from './images/TRADIES_MAIN_1500x800-min.jpg';


const links = [
    { path: "/tradieshealth", label: "Tradies health (general)" },
    { path: "/your-body/lower-back/acute-non-specific-low-back-pain", label: "Acute non-specific low back pain" },
    { path: "/your-body/ankle/ankle-sprain", label: "Ankle sprain" },
    { path: "/your-body/leg/calf-tears", label: "Calf tears" },
    { path: "/your-body/fingers/dislocated-finger—volar-plate", label: "Dislocated finger" },
    { path: "/your-lifestage/ageing-well/fractures", label: "Fractures" },
    { path: "/your-body/hip/groin-pain", label: "Groin pain" },
    { path: "/your-body/leg/hamstring-tears", label: "Hamstring tears" },
    { path: "/your-body/knee/cartilage-injuries", label: "Knee cartilage injuries" },
    { path: "/your-body/knee/ligament-injuries", label: "Knee ligament injuries" },
    { path: "/your-lifestage/adults/mens-health", label: "Men's health" },
    { path: "/your-body/leg/quadriceps-tears", label: "Quadriceps tears" },
    { path: "/your-wellbeing/return-exercise", label: "Return to exercise" },
    { path: "/your-body/shoulder/shoulder-dislocation", label: "Shoulder dislocation" },
    { path: "/your-body/elbow/tennis-elbow", label: "Tennis elbow" },
    { path: "/your-body/wrist/wrist-sprains", label: "Wrist sprain" }
];

export default function ForTradies(){
        const backgroundImg = backImg;
        const metaTitle = "Your body | Choose physio"
        const metaDescription = "Find a physio. Your GP's most recommended health professional. Choose physio to help you recover from injury, reduce pain and stiffness, increase mobility and prevent further injury."
        const dispatch = useDispatch()
        useEffect(() => {
            dispatch(hideFullscreenFeatured());
            }, [dispatch])
        return(
            <div id="page_content" className="inner_gap for-tradies">
                <SeoMetaTags 
                    title={metaTitle} 
                    description={metaDescription} 
                    twitterImg="" 
                    ogImg="" />

                <div className="fullWidth_container full_height img_background" style={{backgroundImage: `url(${backgroundImg})` }}>
                    <div className="site_container">
                        <h1 className="accent_primary_heading left lg">
                            <span className="sub">Choose physio</span> 
                            <br/> for tradies health
                        </h1>
                        <h3 className="sub_heading bright sm_top_gap">What can we help you with?</h3>
                        <div className="btn_grid condition_grid col_2">
                            {links.map((link, index) => (
                                <Link to={link.path} className="grid_column" key={index}>
                                    {link.label}
                                </Link>
                            ))}
                        </div>
                        <BackToPrevBtn />
                    </div>  
                </div>
            </div>
        );
   
}