import React, { useState, useEffect} from 'react'
import axios from 'axios' 
import { configPara } from './APIUrlConfig'
// css
import './FAPMaintenance.sass'
export default function FAPMaintenance(){
  const fapWordsURL = configPara.url.concat('/ndisandtelehealth_rest_api')
  const [fapWords, setFAPWords] = useState({
    FAPWords: ""
  })
  useEffect(() => {
    axios.get(fapWordsURL,{auth: configPara.auth})
    .then(response =>{
      setFAPWords(
        {
          FAPWords: response.data.FAPWords
        })
        
    })
    .catch(error =>console.log(error))
    
}, [fapWordsURL])
     return(
                           
      <div className="FAPWords">
        { fapWords.FAPWords }
      </div>
               
      );
  
}      