const initialState = {
    condition: []
}

const CLEAR_STATE = "CLEAR_STATE";
const LOAD_SINGLE_CONDITION = "LOAD_SINGLE_CONDITION";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_SINGLE_CONDITION: {
            return {...state,
                condition: action.payload}
        }
        case CLEAR_STATE: {
            return {
                condition: []
            }
        }
        default:
            return state;
    }
    
}