const initialState = {
    loggedIn: true
}

const ADMIN_LOGIN = "ADMIN_LOGIN";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ADMIN_LOGIN: {
            return {...state,
                loggedIn: action.payload}
        }
        default:
            return state;
    }
    
}