import React, { useState } from 'react'
import './App.sass'
import { BrowserRouter as Router } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from "react";
import { useLocation } from "react-router-dom";


// COMPONENTS
import Footer from './components/layout/Footer'
import Layout from './Pages/Layout'
import MainNavigation from './components/layout/MainNavigation'

import Container from '@material-ui/core/Container'
import { TextField, Button, InputAdornment, IconButton, CircularProgress, SnackbarContent  } from '@material-ui/core'

// action
import { setAdminLogin } from './redux/actions/adminLoginAction'
// icons
import { Visibility, AccountCircle, VisibilityOff } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error'

//GTM
import TagManager from 'react-gtm-module'

export default function App(){
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  const [userId, setUserId] = useState("")
  const [userPassword, setUserPassword] = useState("")

  const [showPassword, setShowPassword] = useState(false)

  const [loginStatus, setLoginStatus] = useState(true)

  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()

  const isAdminLogin = useSelector(state => state.adminLogin.loggedIn)
  const showFeatured = useSelector(state => state.fullscreenFeatured.visibility);
  
  const handleCloseWarning = () => {
    setLoginStatus(true)
  }

  const handleAdminLogin = () => {
    if( userId === "admin" && userPassword === "Apa12345" ){
      setIsLoading(true)
      setLoginStatus(true)
      dispatch(setAdminLogin(true))
    } else {
      setLoginStatus(false)
      setIsLoading(false)
    }
  }

  const tagManagerArgs = {
    gtmId: 'GTM-NBK796D'
  }
  TagManager.initialize(tagManagerArgs)

  return (
    <div id="site_container">
      { isAdminLogin ? (
        <Router>
          <ScrollToTop />
          { !showFeatured &&
          <MainNavigation />
          }
          <Layout />
          <Footer />
        </Router>
      ) : (
        <div id="admin-login" className="fadeInUp">
          <h3>Admin Login</h3>
          <Container fixed className="login-form">
            <form className="form" noValidate autoComplete="off">
              <TextField
                id="username"
                variant="outlined"
                type='text'
                label="User Name"
                value={userId}
                onChange={e => setUserId(e.target.value)}
                InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AccountCircle />
                  </InputAdornment>
                ),
                }}
              />
              <TextField
                id="password"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                label="Password"
                value={userPassword}
                onChange={e => setUserPassword(e.target.value)}
                InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="toggle password visibility"
                          onClick={ () => { setShowPassword(!showPassword) } }
                          onMouseDown={(event) => { event.preventDefault() } }
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
                }}
              />
              { !loginStatus &&
                <SnackbarContent
                  className="error_warning"
                  aria-describedby="client-snackbar"
                  message={
                  <span id="client-snackbar">
                    <ErrorIcon />
                    Invalid login credentials.
                  </span>
                  }
                  action={[
                      <IconButton key="close" aria-label="close" color="inherit" onClick={handleCloseWarning}>
                          <CloseIcon />
                      </IconButton>
                  ]}
                />
              }
              <div>
                <Button onClick={handleAdminLogin}
                  className="login_btn"
                  variant="outlined" size="large" disabled={isLoading}
                  color="primary">
                  Login</Button>
                {isLoading && <CircularProgress size={24} />}
              </div>
            </form>
          </Container>
        </div>
      )}
      
    </div> 
  )
}