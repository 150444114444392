import React from 'react'

import { Marker } from '@react-google-maps/api'
import {relatedMarkerIcon} from './MarkerIcon'
import MarkerInfoWindow from './MarkerInfoWindow'

export default function RelatedMarkers(props) {
    return (
        <React.Fragment>
            { props.allPractices.resultAll.map( 
                practice => {
                    const location = {
                        lat: parseFloat(practice.Lat), 
                        lng: parseFloat(practice.Lon)
                    }
                    const isMainCoord = props.allPractices.result.some(x => x.PracticeID === practice.PracticeID)
                    //console.log(isMainCoord)
                    return(
                        !isMainCoord && 
                            <Marker className="marker"
                                key={practice.PracticeID}
                                position={location}
                                icon={props.encodeSvg(relatedMarkerIcon)}
                                onClick={() => {props.handleMarkerClick(practice.PracticeID)}} >
                                    {practice.PracticeID === props.selectedPractice.selectedId && 
                                        <MarkerInfoWindow 
                                            location={location}
                                            handleClosePracticeInfo={props.handleClosePracticeInfo}
                                            practice={practice}
                                        />
                                    }
                            </Marker>
                    )
                })
            }
        </React.Fragment>
    )
}
