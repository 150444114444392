import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

// css
import "./Layout.sass";

//pages
import PhysioCanTreat from "./PhysioCanTreat";
import CampaignBestMove from "./CampaignBestMove";
import WhatIsPhysio from "./WhatIsPhysio";
import FindAPhysio from "./FindAPhysio";
import Search from "./Search";
import PageNotFound from "./PageNotFound";
import axios from "axios";
// second level pages
import YourBody from "./YourBody";
import YourCondition from "./YourCondition";
import YourWellbeing from "./YourWellbeing";
import YourLifestage from "./YourLifestage";

// third level page
import ConditionThirdLevelPage from "./ConditionThirdLevelPage";

import SingleConditionPage from "./SingleConditionPage";
import TelehealthPage from "./TelehealthPage";
import SingleSecondLevelPage from "./SingleSecondLevelPage";
import SinglePhysio from "./SinglePhysio";
import PainJoin from "./PainJoin";
import ForTradies from "./ForTradies";
import { configPara } from "../components/globalComponents/APIUrlConfig";
import FAPMaintenance from "../components/globalComponents/FAPMaintenance";
export default function Layout() {

  const msgCheck = (msg) => {
    msg = msg.replace(/\/\/.*$|\/\*[\s\S]*?\*\//gm, "");
    const matches = msg.match(/[a-zA-Z]/g);
    return (matches ? matches.length : 0) > 0 ? msg : null;
  };

  const fapTagURL = configPara.url.concat("/ndisandtelehealth_rest_api");
  const [fapTagStatus, setFAPTag] = useState({
    FAPTag: 0,
    fapChecked: false,
  });

  const [maintenanceMode, setMaintenanceMode] = useState({
    loaded: false,
    maintenanceMsg: null,
  });

  const maintenceModeJsonUrl = "/maintenance_mode_on.txt";
  useEffect(() => {
    const fetchMaintenanceMode = async () => {
      console.log("check maintenance mode txt file");
      try {
        const response = await axios.get(maintenceModeJsonUrl, { responseType: "text" });
        console.log("maintenance msg response ");
        console.log("---- ");
        console.log(response.data);
        console.log("---- ");                
        setMaintenanceMode({
          loaded: true,
          maintenanceMsg: msgCheck(response.data),
        });
      } catch (error) {
        console.log("maintenance msg err " + error);
        setMaintenanceMode({ loaded: true });
      }
    };
  
    fetchMaintenanceMode();
  }, []);

  useEffect(() => {
    if (maintenanceMode.loaded && !maintenanceMode.maintenanceMsg) {
    console.log("get FAPTag data");
      // Fetch FAPTag data only after maintenanceMode is loaded and no maintenance message is present
      axios
        .get(fapTagURL, { auth: configPara.auth })
        .then((response) => {
            console.log("FAPTag msg response ");
            console.log("---- ");
            console.log(response.data);
            console.log("---- ");
          setFAPTag({
            FAPTag: response.data.FAPTag,
            fapChecked: true,
          });
          if (response.data.site_maintenance_mode === 1) {
            setMaintenanceMode({
              loaded: true,
              maintenanceMsg: response.data.site_maintenance_message,
            });
          }
        })
        .catch((error) => {
            console.log("FAPTag msg err " + error);
          console.log(error);
          setFAPTag({
            fapChecked: true,
          });
        });
    }
  }, [maintenanceMode.loaded, fapTagURL]);

  if (!maintenanceMode.loaded || (!fapTagStatus.fapChecked && !maintenanceMode.maintenanceMsg)) {
    console.log("show blank loading page");
    return (<div className="main_container"></div>);
  }

  if (maintenanceMode.maintenanceMsg) {
    console.log("show maintenance message");
    return (
      <div className="main_container">
        <style>
          {`
            header {
              display: none;
            }
          `}
        </style>
        <PhysioCanTreat maintenanceMsg={maintenanceMode.maintenanceMsg} />
      </div>
    );
  }
  
  console.log("show full page");
  const redirects = [
    {
      from: "/women’s-health",
      to: "/your-lifestage/motherhood/women’s-health",
    },
    { from: "/exercise", to: "/your-wellbeing/return-exercise" },
    { from: "/elective-surgery", to: "/your-wellbeing/pre-and-post-surgery" },
    { from: "/running", to: "/your-wellbeing/running" },
    { from: "/shoulder-pain", to: "/your-body/shoulder/shoulder-pain" },
    { from: "/sore-knee", to: "/your-body/knee/sore-knee-patellofemoral-pain" },
    { from: "/sore-ankle", to: "/your-body/ankle/ankle-sprain" },
    { from: "/neck-pain", to: "/your-body/neck/acute-neck-pain" },
    {
      from: "/back-pain",
      to: "/your-body/lower-back/acute-non-specific-low-back-pain",
    },
    { from: "/headache", to: "/your-body/head/headache" },
    { from: "/golf", to: "/your-body/elbow/golfer’s-elbow" },
    { from: "/ankle", to: "/your-body/ankle" },
    { from: "/pregnancy", to: "/your-lifestage/motherhood/pre-and-post-natal" },
    { from: "/findaphysio", to: "/find-a-physio" },
    { from: "/physical-activity", to: "/your-wellbeing/physical-activity" },
    { from: "/women-health", to: "/your-lifestage/motherhood/women’s-health" },
    {
      from: "/women's-health",
      to: "/your-lifestage/motherhood/women’s-health",
    },
    {
      from: "/tradieshealth/tradieshealth/getinvolved",
      to: "/tradieshealth/getinvolved",
    },
    {
      from: "/tradieshealth/tradieshealth/tradieshealth",
      to: "/tradieshealth",
    },
    {
      from: "/return-to-sport/return-to-sport/return-to-sport",
      to: "/return-to-sport",
    },
    { from: "/prostate-cancer", to: "/your-condition/prostate-cancer" },
    { from: "/cancer", to: "/your-condition/cancer" },
    { from: "/lung-cancer", to: "/your-body/chest/lung-cancer" },
    { from: "/breast-cancer", to: "/your-condition/breast-cancer" },
    { from: "/stroke", to: "/your-condition/stroke-recovery" },
    { from: "/aged-care", to: "/your-lifestage/ageing-well/aged-care" },
    {
      from: "/your-lifestage/ageing-well/bowel-and-bladder-health",
      to: "/your-wellbeing/bowel-and-bladder-health",
    },
    {
      from: "/your-condition/bowel-and-bladder-health",
      to: "/your-wellbeing/bowel-and-bladder-health",
    },
    { from: "/pain", to: "/your-condition/pain-general" },
  ];

  return (
    <div className="main_container">
      <Switch>
        {redirects.map((redirect, index) => (
          <Redirect key={index} exact from={redirect.from} to={redirect.to} />
        ))}

        <Route exact path="/" component={PhysioCanTreat} />
        <Route exact path={["/tim", "/silvia"]} component={CampaignBestMove} />
        <Route path="/what-is-physio" component={WhatIsPhysio} />
        {fapTagStatus.FAPTag ? (
          <Route path="/find-a-physio" component={FAPMaintenance} />
        ) : (
          <Route path="/find-a-physio" component={FindAPhysio} />
        )}
        {fapTagStatus.FAPTag ? (
          <Route path="/find-detail/" component={FAPMaintenance} />
        ) : (
          <Route path="/find-detail/" component={SinglePhysio} />
        )}

        <Route path="/search" component={Search} />

        <Route exact path="/your-body" component={YourBody} />

        {/* MATCH SINGLE CONDITION PAGE FIRST */}
        <Route
          path="/your-body/:category/:condition"
          component={SingleConditionPage}
        />
        <Route
          path="/your-body/:category"
          component={ConditionThirdLevelPage}
        />
        <Route exact path="/your-condition/pain-joint" component={PainJoin} />
        <Route
          exact
          path="/your-condition/:condition"
          component={SingleSecondLevelPage}
        />
        <Route exact path="/your-condition" component={YourCondition} />
        <Route
          exact
          path="/your-wellbeing/:condition"
          component={SingleSecondLevelPage}
        />
        <Route exact path="/your-wellbeing" component={YourWellbeing} />
        <Route exact path="/your-lifestage" component={YourLifestage} />
        <Route
          path="/your-lifestage/:category/:condition"
          component={SingleConditionPage}
        />
        <Route
          path="/your-lifestage/:category"
          component={ConditionThirdLevelPage}
        />

        <Route path="/telehealth" component={TelehealthPage} />
        <Route
          path="/tradieshealth/getinvolved"
          component={SingleConditionPage}
        />
        <Route
          path={["/tradieshealth", "/tradies"]}
          component={SingleConditionPage}
        />
        <Route path="/return-to-sport" component={SingleConditionPage} />
        <Route path="/fortradies" component={ForTradies} />

        {/* 404 */}
        <Route component={PageNotFound} />
      </Switch>
    </div>
  );
}
