import React, {useState} from 'react'
import { NavLink, useLocation} from 'react-router-dom'
//import { useSelector } from 'react-redux'

// CSS
import './MainNavigation.sass'

// bootstrap elements
import { Navbar, Nav } from 'react-bootstrap'

// icons
//import Home from '@material-ui/icons/Home'
import Search from '@material-ui/icons/Search'
import { ReactComponent as HomeIcon} from '../physiocantreat/images/PHYSIO_CAN_TREAT_30px.svg'
import { ReactComponent as WhatIsPhysioIcon} from '../physiocantreat/images/WHAT_IS_PHYSIO_30px.svg'
import { ReactComponent as FAPIcon} from '../physiocantreat/images/FIND_A_PHYSIO_30px.svg'
// images
import logo from './images/APA_H_LOGO.png'

function MainNavigation() {
    const [navExpanded, setNavExpanded] = useState(false)
    const refreshFAP=() =>{
        setNavExpanded(false)
        window.location.replace("/find-a-physio");
    }
    const menuStr = useLocation().pathname
    const activeMenu = menuStr.includes('your-body') || menuStr.includes('your-condition') ||menuStr.includes('your-wellbeing') ||menuStr.includes('your-lifestage')? "nav-link highlight-menu":"nav-link"
    
    return (
        <header id="header">
            <div className="site_container">
                <Navbar 
                    expand="lg"
                    onToggle={() => setNavExpanded('expanded')}
                    expanded={navExpanded}
                >
                    <NavLink to={'/'}><img className="logo" src={logo} alt="Choose Physio Logo"/></NavLink>
                    <Navbar.Toggle aria-controls="primary-navbar-nav" >
                        <span className="dash" />
                        <span className="dash" />
                        <span className="dash" />
                        <Search className="search_icon" />
                    </Navbar.Toggle>
                    <Navbar.Collapse id="primary-navbar-nav">
                        <Nav className="ml-auto" >
                            <NavLink 
                                onClick={() => setNavExpanded(false)} 
                                exact className={activeMenu}
                                activeClassName="active" to={'/'}
                            >
                                <HomeIcon /> Physio can treat
                            </NavLink>
                            <NavLink 
                                onClick={() => setNavExpanded(false)} 
                                className="nav-link" 
                                activeClassName="active" 
                                to={"/what-is-physio"}>
                                    <WhatIsPhysioIcon /> What is physio?
                            </NavLink>
                            <NavLink 
                                onClick={() => refreshFAP() } 
                                className="nav-link" 
                                activeClassName="active" 
                                to={"/find-a-physio"}>
                                    <FAPIcon /> Find a physio
                            </NavLink>
                            <NavLink 
                                onClick={() => setNavExpanded(false)} 
                                className="nav-link search" 
                                to={"/search"}>
                                    <Search />
                            </NavLink>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        </header>
    )
}

export default MainNavigation
