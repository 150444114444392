const initialState = {
    shortenUrlList: []
}

const LOAD_SHORTEN_URL_LIST = "LOAD_SHORTEN_URL_LIST";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_SHORTEN_URL_LIST: {
            return {...state,
                shortenUrlList: action.payload}
        }
        default:
            return state;
    }
    
}