import axios from 'axios'
import { configPara } from '../../components/globalComponents/APIUrlConfig'

const SET_RESULT_VISIBLE = "SET_RESULT_VISIBLE"
const SET_RESULT_HIDDEN = "SET_RESULT_HIDDEN"
const SEARCH_EMPTY = "SEARCH_EMPTY"
const SEARCH_PRACTICE = "SEARCH_PRACTICE"
const SEARCH_PARAMETERS = "SEARCH_PARAMETERS"

const searchPracticeApiUrl = configPara.url.concat('/fapsearch_rest_api')

const fetchLanguageClinicalApiUrl = configPara.url.concat('/dropdown_rest_api/dropdown_resource')

export const getSearchResults = (payload) => {
    return {
        type: SEARCH_PRACTICE,
        payload
    }
}

export const setSearchPara = (payload) => {
    return {
        type: SEARCH_PARAMETERS,
        payload
    }
}

export const searchEmpty = (payload) => {
    return {
        type: SEARCH_EMPTY,
        payload
    }
}

export const setResultsVisible = (payload) => {
    return {
        type: SET_RESULT_VISIBLE,
        payload
    }
}

export const setResultsHidden = (payload) => {
    return {
        type: SET_RESULT_HIDDEN,
        payload
    }
}

export const practiceSearch = (searchPara) => {
    return (dispatch) => {
        let time = 0
        setInterval(() => {
            time = time + 1
        }, 1000)
        return new Promise((resolve, reject) => {
            //console.log('%c Api Request Initiated!', 'background: #222 color: #bada55 padding: 5px')
            const headers = {
                'Content-Type': 'application/json'
            }
            axios.post(searchPracticeApiUrl, searchPara, {
                    timeout: 240000,
                    headers: headers, 
                    auth: configPara.auth
                })
                .then(response => {
                    dispatch(getSearchResults(response.data))
                    if( response.data.length < 1 ){
                        dispatch(searchEmpty(true))
                    } else {
                        dispatch(searchEmpty(false))
                    }
                    // console.log('%c Success! Returning Data...', 'background: #222 color: #bada55 padding: 5px')
                    // console.log(`%c Time: ${time}`, 'background: #222 color: #bada55 padding: 5px')
                    // console.log(response.data)
                    resolve(response.data)
                })
                .catch(error => {
                    if (error.response) {
                        /*
                            * The request was made and the server responded with a
                            * status code that falls out of the range of 2xx
                            */
                        console.log(error.response.data)
                        console.log(error.response.status)
                        console.log(error.response.headers)
                    } else if (error.request) {
                        /*
                            * The request was made but no response was received, `error.request`
                            * is an instance of XMLHttpRequest in the browser and an instance
                            * of http.ClientRequest in Node.js
                            */
                        console.log(error.request)
                    } else {
                        // Something happened in setting up the request and triggered an Error
                        console.log('Error', error.message)
                    }
                    console.log(error.config)
                    reject("Error")
                    // console.clear()
                })
            })
    }
}

export const fetchLanguageClinical = () => {
    return new Promise((resolve, reject) => {
        const headers = {
            'Content-Type': 'application/json'
        }
        axios.get(fetchLanguageClinicalApiUrl, {
                timeout: 240000,
                headers: headers, 
                auth: configPara.auth
            })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                if (error.response) {
                    /*
                        * The request was made and the server responded with a
                        * status code that falls out of the range of 2xx
                        */
                    console.log(error.response.data)
                    console.log(error.response.status)
                    console.log(error.response.headers)
                } else if (error.request) {
                    /*
                        * The request was made but no response was received, `error.request`
                        * is an instance of XMLHttpRequest in the browser and an instance
                        * of http.ClientRequest in Node.js
                        */
                    console.log(error.request)
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message)
                }
                console.log(error.config)
                reject("Error")
                //console.clear()
            })
    })
}