import React from 'react'
import { Result, Button } from 'antd'
import { useHistory } from 'react-router'

// css
import './PageNotFound.sass'

export default function PageNotFound() {
    let history = useHistory()

    const handle404Redirect = () => {
        history.push('/search')
    }
    return (
        <div id="page_content" class="page-not-found">
            <div className="site_container">
                <Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the page you visited does not exist."
                    extra={<Button type="primary"
                                    onClick={handle404Redirect}>
                                        Try a search
                            </Button>} />
            </div>
        </div>
    )
}