import axios from 'axios'
import { configPara } from '../../components/globalComponents/APIUrlConfig'

const SEARCH_CONTENT = "SEARCH_CONTENT";
const SEARCH_TERM = "SEARCH_TERM";
const SEARCH_EMPTY = "SEARCH_EMPTY";

const apiUrl = configPara.url.concat('/search');

export const getSearchResults = (payload) => {
    return {
        type: SEARCH_CONTENT,
        payload
    }
}

export const setSearchTerm = (payload) => {
    return {
        type: SEARCH_TERM,
        payload
    }
}

export const searchEmpty = (payload) => {
    return {
        type: SEARCH_EMPTY,
        payload
    }
}

export const contentSearch = (term) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios.get(`${apiUrl}/${term}`, {auth: configPara.auth})
                .then(response => {
                    console.log(response.data)
                    dispatch(getSearchResults(response.data))
                    dispatch(setSearchTerm(term))
                    if( response.data.length < 1 ){
                        dispatch(searchEmpty(true))
                    } else {
                        dispatch(searchEmpty(false))
                    }
                    resolve("success")
                })
                .catch(error => {
                    if (error.response) {
                        /*
                         * The request was made and the server responded with a
                         * status code that falls out of the range of 2xx
                         */
                        //console.log(error.response.data)
                        //console.log(error.response.status)
                        //console.log(error.response.headers)
                    } else if (error.request) {
                        /*
                         * The request was made but no response was received, `error.request`
                         * is an instance of XMLHttpRequest in the browser and an instance
                         * of http.ClientRequest in Node.js
                         */
                        //console.log(error.request)
                    } else {
                        // Something happened in setting up the request and triggered an Error
                        //console.log('Error', error.message)
                    }
                    //console.log(error.config)
                    reject("Error")
                    //console.clear()
                })
        })
    }
}