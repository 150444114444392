import React, { Component } from 'react'
import MetaTags from 'react-meta-tags'

export default class SeoMetaTags extends Component{
    render() {
        const metaTitle = this.props.title
        const metaDescription = this.props.description
        const pageUrl = window.location.href

        return(
            <MetaTags>
                <meta name="charset" content="UTF8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimal-ui" />
                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-title" content="Choose Physio" />
                <meta name="application-name" content="Choose Physio" />
                <meta name="author" content="Australian Physiotherapy Association" />
                {/* <meta name="msapplication-config" content="browserconfig.xml" /> */}
                {/* <meta name="msapplication-TileColor" content="#ffffff" /> */}
                {/* <meta name="msapplication-TileImage" content="https://unsplash.com/mstile-144x144.png" /> */}
                <meta name="theme-color" content="#ffffff" />
                <meta httpEquiv="Accept-CH" content="DPR, Width, Viewport-Width" />
                <meta name="description" content={metaDescription} />
                <meta name="twitter:site" content="@apaphysio" />
                <meta name="twitter:title" content={metaTitle} />
                <meta name="twitter:description" content={metaDescription} />
                <meta name="twitter:url" content={pageUrl} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:image" content="https://images.unsplash.com/photo-1558980664-3a031cf67ea8?ixlib=rb-1.2.1&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjEyMDd9" />
                <meta property="og:title" content={metaTitle} />
                <meta property="og:description" content={metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={pageUrl} />
                <meta property="og:image" content="http://images.unsplash.com/photo-1558980664-3a031cf67ea8?ixlib=rb-1.2.1&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjEyMDd9" />
                <meta property="og:image:secure_url" content="https://images.unsplash.com/photo-1558980664-3a031cf67ea8?ixlib=rb-1.2.1&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjEyMDd9" />
                <title>{metaTitle}</title>
            </MetaTags>
        );
    }
}