import React, {useState, useLayoutEffect} from 'react'
import ReactDOM from 'react-dom'

import { FormControl, InputLabel, Select, OutlinedInput } from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

function LanguageSelectionField(props) {
    const [languageLabelWidth, setLanguageLabelWidth] = useState(0)

    useLayoutEffect(() => {
        setLanguageLabelWidth(ReactDOM.findDOMNode(props.languageRef.current).offsetWidth)
    }, [props.languageRef])

    const languageList = props.languages.map(language => {
        if(language.ID !== "11") return <option value={language.ID} key={language.ID}>{language.Name}</option>
        return null;
    }
     
       
    
    )

    return (
        <FormControl variant="outlined" className="input_language">
            <InputLabel htmlFor="input_language" 
                ref={props.languageRef} >
                    Select language (other than English)
            </InputLabel>
            <Select
                native
                IconComponent = {ExpandMoreIcon}
                value={props.selectedLanguage}
                onChange={e => props.handleSelectedLanguage(e.target.value) }
                input={
                    <OutlinedInput name="input-language" 
                            labelWidth={languageLabelWidth} 
                            id="input_language" />
                }
            >
                <option value="" />
                {languageList}
            </Select>
        </FormControl>
    )
}

export default LanguageSelectionField
