import React, {useState, useLayoutEffect} from 'react'
import ReactDOM from 'react-dom'

import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

export default function PracticeDisplayOption(props) {
    const [displayLabelWidth, setDisplayLabelWidth] = useState(0)

    // get+set label width on dom load
    useLayoutEffect(() => {
        setDisplayLabelWidth(ReactDOM.findDOMNode(props.DisplayRef.current).offsetWidth)
    }, [props.DisplayRef])

    return (
        <FormControl variant="outlined" className="display_items" disabled={props.isDataLoaded ? false : true} >
            <InputLabel htmlFor="display-items" 
                ref={props.DisplayRef} >
                Practices
            </InputLabel>
            <Select
                native
                IconComponent = {ExpandMoreIcon}
                value={props.NumItem}
                onChange={(e) => { props.handleDisplayChange(e.target.value) }}
                input={
                    <OutlinedInput name="items" labelWidth={displayLabelWidth} id="display-items" />
                }
            >   
                <option value="6">Display 6</option>
                <option value="12">Display 12</option>
            </Select>
        </FormControl>
    )
}