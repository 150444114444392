import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// components
import BackToPrevBtn from '../components/globalComponents/BackToPrevBtn'
import SeoMetaTags from '../components/globalComponents/SeoMetaTags'

// css
import './YourBody.sass'


// redux action
import { fetchAllBlock } from '../redux/actions/fetchAllBlockAction'
import YourBodyAds from '../components/globalComponents/YourBodyAds' 
import { hideFullscreenFeatured } from '../redux/actions/fullscreenFeaturedAction'

const links = [
    { path: "/your-body/ankle", label: "Ankle" },
    { path: "/your-body/chest", label: "Chest" },
    { path: "/your-body/elbow", label: "Elbow" },
    { path: "/your-body/fingers", label: "Fingers" },
    { path: "/your-body/foot", label: "Foot" },
    { path: "/your-body/hand", label: "Hand" },
    { path: "/your-body/head", label: "Head" },
    { path: "/your-body/hip", label: "Hip" },
    { path: "/your-body/knee", label: "Knee" },
    { path: "/your-body/leg", label: "Leg" },
    { path: "/your-body/lower-back", label: "Lower back" },
    { path: "/your-body/neck", label: "Neck" },
    { path: "/your-body/pelvis", label: "Pelvis" },
    { path: "/your-body/shoulder", label: "Shoulder" },
    { path: "/your-body/wrist", label: "Wrist" },
];

export default function YourBody(){
    
        const dispatch = useDispatch();
        useEffect(() => {
            dispatch(hideFullscreenFeatured());
            }, [dispatch])
        useEffect(() => {
            dispatch(fetchAllBlock());
        }, [dispatch]);

        const Blocks = useSelector(state => state.fetchAllBlock.conditionBlocks);
        const pageUrl = window.location.pathname;
        const blocktitle = Blocks.filter(conditionBlock=>conditionBlock.field_pageurl === pageUrl).map(conditionBlock =>  conditionBlock.title);
        const backgroundImg = Blocks.filter(conditionBlock=>conditionBlock.field_pageurl === pageUrl).map(conditionBlock => conditionBlock.field_background_image);
        const metaTitle = "Your body | Choose physio"
        const metaDescription = "Head, shoulders, knees, toes and everything in between - physio can help you."

        return(
            <div id="page_content" className="inner_gap your-body">
                <SeoMetaTags 
                    title={metaTitle} 
                    description={metaDescription} 
                    twitterImg="" 
                    ogImg="" />

                <div className="fullWidth_container full_height img_background" style={{backgroundImage: `url(${backgroundImg})` }}>
                    <div className="site_container">
                        <h1 className="accent_primary_heading left lg">
                            <span className="sub">Choose physio</span> 
                            <br/> for {blocktitle}
                        </h1>
                        
                        <h3 className="sub_heading bright sm_top_gap">Choose the area you need help with:</h3>
                        <div className="btn_grid condition_grid col_4">
                            {links.map((link, index) => (
                                <Link key={index} to={link.path} className="grid_column">
                                {link.label}
                                </Link>
                            ))}
                        </div>
                        <BackToPrevBtn />
                    </div>  
                </div>
                <YourBodyAds />
            </div>
               
        );
   
}