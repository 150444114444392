import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hideFullscreenFeatured } from "../redux/actions/fullscreenFeaturedAction";
import { Link, useLocation } from "react-router-dom";
import "./CampaignBestMove.sass";

// 404 not found layout
import { Result, Button } from 'antd'
import { useHistory } from 'react-router'
// css
import './PageNotFound.sass'
// 404 not found layout end;

export default function CampaignBestMove() {

  // page not found component:
  let history = useHistory()

  const handle404Redirect = () => {
    history.push('/search')
  }
  // page not found component end;

  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const location = useLocation();

  const isSilvia = window.location.href.includes("silvia");

  useEffect(() => {
    dispatch(hideFullscreenFeatured());

    const fetchAddress = isSilvia
      ? "/campaign_bestmove/silvia/silvia.json" 
      : "/campaign_bestmove/tim/tim.json";

    // Fetch data from the JSON file
    fetch( fetchAddress )
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.error("Error fetching data:", error));
  }, [dispatch, location.pathname, isSilvia]);

  useEffect(() => {
    if (data) {
      window.scrollTo(0, 0);
    }
  }, [data]); // Depend on data
  
  if (!data) {
    return <div>Loading...</div>;
  }
  
  const { learnMoreIcons, meetOtherBlurb, meetOtherImg, mainBlurb, youtubeID, styleSrc, headerImage, pageLive } = data;

  if(pageLive === "on") {
    return (
      <div id="page_content" style={{ fontFamily: "sans-serif" }}>
        <link rel="stylesheet" href="/campaign_bestmove/style.css" />
        <link rel="stylesheet" href={styleSrc} />
        <div className="container-header">
          <div className="campaign-container">
            <div className="row row-header">
              <img className="campaign-headshot" src={headerImage} alt="" />
              <div className="campaign-cta">
                <h1>Make your best move yet.</h1>
                <h2>Find an APA physio near you today.</h2>
                <Link to="/find-a-physio"><button className="btn btn-primary">
                  <span>Find a physio</span>
                  <span className="vert-bar"></span>
                  <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                  </svg>
                </button></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="campaign-container container-exposition">
          
          <div className="row row-exposition">
            <div className="col-expo-blurb col-lg-12 col-xl-5 p-lg-0 p-xl-3">
              <div dangerouslySetInnerHTML={{ __html: mainBlurb }}></div>
              <Link to="/find-a-physio"><button className="btn btn-primary">Find a physio</button></Link>
            </div>
            <div className="col-expo-video col-lg-12 col-xl-7 p-lg-0 p-xl-3">
              <iframe title="Best move" className="youtube-video" src={"https://www.youtube.com/embed/"+youtubeID} frameborder="0" allowFullScreen></iframe>
            </div>
          </div>
  
          <div className="row row-learn-more justify-content-start">
            <div className="col-12 p-lg-0 p-xl-3">
              <h2>Learn more about</h2>
              <div className="learn-more">
                {learnMoreIcons.map((icon, index) => (
                    <div className="learn-more-icon">
                      <Link key={index} to={icon.link}>
                      <img src={icon.img} alt={icon.text.replace(/[<>]+/g, ' ')} />
                      <span dangerouslySetInnerHTML={{ __html: icon.text }}></span>
                      </Link>
                    </div>
                ))}
              </div>
            </div>
          </div>
          
          <div className="row row-meet-other justify-content-center">
            <div className="col-meet-other-video col-lg-12 col-xl-5 p-0">
              <img className="meet-other-panel video-frame" src={meetOtherImg} alt="" />
            </div>
            <div className="col-lg-12 col-xl-5 p-0">
              <div className="col-meet-other-blurb meet-other-panel blurb">
                <div dangerouslySetInnerHTML={{ __html: meetOtherBlurb }}></div>
                <Link to={(isSilvia?"/tim":"/silvia")}><button className="btn btn-primary">Learn more</button></Link>
              </div>
            </div>
          </div>
  
        </div>
      </div>
    );
  } else {
    return (
      <div id="page_content" class="page-not-found">
          <div className="site_container">
              <Result
                  status="404"
                  title="404"
                  subTitle="Sorry, the page you visited does not exist."
                  extra={<Button type="primary"
                                  onClick={handle404Redirect}>
                                      Try a search
                          </Button>} />
          </div>
      </div>
    );
  }
}
