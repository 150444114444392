const SHOW_FULLSCREEN_FEATURED = "SHOW_FULLSCREEN_FEATURED";
const HIDE_FULLSCREEN_FEATURED = "HIDE_FULLSCREEN_FEATURED";
const SHOW_CONTENT = "SHOW_CONTENT";

export function showFullscreenFeatured() {
    return { type: SHOW_FULLSCREEN_FEATURED }
};

export function hideFullscreenFeatured() {
    return { type: HIDE_FULLSCREEN_FEATURED }
};

export const showContent = () => {
    return { type: SHOW_CONTENT }
};