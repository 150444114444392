import axios from 'axios'
import { configPara } from '../../components/globalComponents/APIUrlConfig'
const LOAD_WELLBEING_ITEMS = "LOAD_WELLBEING_ITEMS";
const apiUrl = configPara.url.concat('/yourwellbeingview');


export const yourMyWellbeingItems = (payload) => {
    return {
        type: LOAD_WELLBEING_ITEMS,
        payload
    }
};

export const fetchMyWellbeing = () => {
    return (dispatch) => {
        axios.get(apiUrl, {auth: configPara.auth})
            .then(response => {
                dispatch(yourMyWellbeingItems(response.data))
            })
            .catch(error => {
                throw(error);
            });
    };
};