const initialState = {
    myWellbeingItems: []
}

const LOAD_WELLBEING_ITEMS = "LOAD_WELLBEING_ITEMS";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_WELLBEING_ITEMS: {
            return {...state,
                myWellbeingItems: action.payload}
        }
        default:
            return state;
    }
    
}