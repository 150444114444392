import axios from 'axios'
import { configPara } from '../../components/globalComponents/APIUrlConfig'

const CLEAR_STATE = "CLEAR_STATE";
const LOAD_SINGLE_CONDITION = "LOAD_SINGLE_CONDITION";
const apiUrl = configPara.url.concat('/custom_rest_api/content_resource');

export const clearCondition = (payload) => {
    return {
        type: CLEAR_STATE,
        payload
    }
}

export const condition = (payload) => {
    return {
        type: LOAD_SINGLE_CONDITION,
        payload
    }
};

export const fetchCondition = (slug) => {
    return (dispatch) => {
        axios.get(apiUrl+slug, {auth: configPara.auth})
            .then(response => {
                dispatch(condition(response.data))
            })
            .catch(error => {
                throw(error);
            });
    };
};