const initialState = {
    grids: []
}

const LOAD_MAIN_GRID = "LOAD_MAIN_GRID";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_MAIN_GRID: {
            return {...state,
            grids: action.payload}
        }
        default:
            return state;
    }
    
}