const initialState = {
    secondLevelconditions: []
}

const LOAD_SECOND_CONDITION = "LOAD_SECOND_CONDITION";
const CLEAR_STATE = "CLEAR_STATE";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_SECOND_CONDITION: {
            return {...state,
                secondLevelconditions: action.payload}
        }
        case CLEAR_STATE: {
            return { 
                secondLevelconditions : [] 
            }
        }
        default:
            return state;
    }
    
}