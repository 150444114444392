import React, { useState, useEffect, useLayoutEffect} from 'react'
import axios from 'axios' 
// css
import './DigitalAds.sass'
export default function DigitalAds(){
      const adsDesktopURL = "https://servedbyadbutler.com/adserve/;ID=167752;size=728x90;setID=326786;type=json;click=CLICK_MACRO_PLACEHOLDER";
      //const adsDesktopURL = "https://servedbyadbutler.com/adserve/;ID=167752;size=728x90;setID=326784;type=json;click=CLICK_MACRO_PLACEHOLDER";
      const adsMobileURL = "https://servedbyadbutler.com/adserve/;ID=167752;size=234x60;setID=326787;type=json;click=CLICK_MACRO_PLACEHOLDER";
      //const adsMobileURL = "https://servedbyadbutler.com/adserve/;ID=167752;size=234x60;setID=326785;type=json;click=CLICK_MACRO_PLACEHOLDER";
      const [adsApiURL, setURL] = useState()
      const [screenWidth, setScreenWidth] = useState(window.innerWidth)
      const [scrollTop, setScrollTop] = useState(0) 
      const [scrolling, setScrolling] = useState(false)
      const [showBob, setShow] = useState(true)
      const [adsData, setAdsData] = useState({
        status: "",
        placements: []
      })
      const [imageData, setImage] = useState({
        alt_text: "",
        image_url: "",
        redirect_url: ""
      });
      //get image data from digital adbulter
      useLayoutEffect(() => {
        function updateScreenWidth() {
          setScreenWidth(window.innerWidth)
          screenWidth > 570 ? setURL(adsDesktopURL) : setURL(adsMobileURL)
        }
        window.addEventListener('resize', updateScreenWidth)
        updateScreenWidth()   
    }, [screenWidth])
      
      useEffect(() =>{
          axios.get(adsApiURL)
          .then(response =>{
            setAdsData(
              {
                status: response.data.status,
                placements: response.data.placements
              })
          })
          .catch(error =>console.log(error))
        
      },[adsApiURL,screenWidth]);

      const imageTotalData = adsData.placements.placement_1;
      useEffect(()=>{
      if(imageTotalData!==undefined) {
        setImage({
          alt_text: imageTotalData.alt_text,
          image_url: imageTotalData.image_url,
          redirect_url: imageTotalData.redirect_url
        })}
      },[adsData,imageTotalData]);
      const imageClick = () =>{
        window.open(imageData.redirect_url, "_blank")
      }
      useEffect(() => {
        window.scrollTo(0,700)
      }, [])
      useEffect(() => {
        const onScroll = e => {
          setScrollTop(e.target.documentElement.scrollTop);
          setScrolling(e.target.documentElement.scrollTop > scrollTop);
        };
        window.addEventListener("scroll", onScroll)
    
        return () => window.removeEventListener("scroll", onScroll);
      }, [scrollTop])
      useEffect(()=>{
        
        const intervalID = setInterval(()=>{
          if(scrolling!==false){
            setShow(false)
          }
          //window.scrollTo(0,0)
        },15000)
        return () => clearInterval(intervalID);
       
      }, [scrollTop,scrolling])
      
     return(
      <div className = "GetCentreLayout">
          <div className="ASection">
            <div className="Desktop">
              { imageData && showBob &&
                <img alt={imageData.alt_text} src={imageData.image_url}  onClick={()=>imageClick()}/>
              }
            </div>
            <div className="Mobile">
              { imageData && showBob &&
                <img alt={imageData.alt_text} src={imageData.image_url}  onClick={()=>imageClick()}/>
              }
            </div>
        </div>
        </div>
      );
  
}      