import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { isMobile } from 'react-device-detect';
import Skeleton from '@material-ui/lab/Skeleton'

// components
import GlobalCTA from '../components/globalComponents/GlobalCTA'
import BackToPrevBtn from '../components/globalComponents/BackToPrevBtn'

// redux action
import { fetchSecondLevelCondition, clearSecondLevelCondition } from '../redux/actions/singleSecondLevelAction'
import { hideFullscreenFeatured } from '../redux/actions/fullscreenFeaturedAction'
// css
import './SingleConditionPage.sass'

import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share'

// icons
import { Share} from '@material-ui/icons'
import { FacebookIcon, LinkedinIcon } from 'react-share'
import UpdatedTwitterIcon from '../components/globalComponents/UpdatedTwitterIcon';
import PointerImg from '../components/globalComponents/images/POINTER.png';
import PointerDownImg from '../components/globalComponents/images/POINTER2.png';

export default function SingleSecondLevelPage(props) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(hideFullscreenFeatured());
        }, [dispatch])
    useEffect(() => {
        const slug = props.match.url;
        dispatch(fetchSecondLevelCondition(slug));
    }, [dispatch, props])

    const singleCondition = useSelector(state => state.fetchSecondLevelCondition.secondLevelconditions);

    const pageUrl = window.location.href;

    const [expanded, setExpanded] = React.useState('');

    const handleChange = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const imageUp = <img alt='icon' src={PointerImg} />;
    const imageDown = <img alt='icon' src={PointerDownImg} />;

    const ContributedNote = () => {        
        return singleCondition.map(condition =>
            <Row className="contributed_section" key={condition.nid}>
                <Col>* {condition.field_contribution_list}</Col>
            </Row>
        )
    }

    useEffect(() => {
        // clear out data before exit
        return () => {
            dispatch(clearSecondLevelCondition())
        };
    }, [dispatch])

    const QuestionList = singleCondition.map(condition => 
        <Row key={condition.nid} className="accordion_qa">
            <Col md={12}>
                {condition.field_multiple_qustions.map(qustion=>
                    <ExpansionPanel key={qustion.id} square expanded={expanded === 'panel'+qustion.id} onChange={handleChange('panel'+qustion.id)}>
                        <ExpansionPanelSummary className="accordion_label">
                            {expanded==='panel'+qustion.id?imageDown:imageUp}
                            <Typography>{qustion.question}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography dangerouslySetInnerHTML={{__html:qustion.answers} } />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                )}
            </Col>
        </Row>
    )
 
    const ConditionMainContent = () => {
        return singleCondition.map(condition =>
            <div className="condition_section" key={condition.nid}>
                <Row>
                    <Col md={12} lg={6} >
                        <h1 className="light_primary_heading no_gap">
                            Choose physio
                        </h1>
                        <h2 className="accent_primary_heading left lg" 
                            style={{maxWidth: '500px'}} >
                            for {condition.title}
                        </h2>
                        <p>{condition.body}</p>
                    </Col>

                    <Col md={12} lg={6} >
                    { (condition.videourl ==="" || isMobile)?
                        (<div className="image_wrapper no_gap" style={{backgroundImage: `url(${condition.field_physio_help_image})`}} >
                            { isMobile &&
                                <img alt={condition.title} src={condition.field_physio_help_image} />
                            }
                        </div>) :
                        (<div className="iframe_wrapper no_gap">
                                <iframe title="Choose Physio"
                                    src={condition.videourl} 
                                    frameBorder="0" allowFullScreen
                                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" >
                                </iframe>
                        </div>)
                    }
                    </Col>
                </Row>

                <Row className="center_content"> 
                    <Col xs={7} md={6} >
                        <BackToPrevBtn variant="no_gap" />
                    </Col>

                    <Col xs={5} md={6} >
                        <div className="share_btn_wrapper">
                            <span className="placeHolder"><Share /> Share</span>
                            <FacebookShareButton url={pageUrl} className="share_btn" >
                                <FacebookIcon size={32} round={false} />
                            </FacebookShareButton>

                            <TwitterShareButton url={pageUrl} className="share_btn" >
                                <UpdatedTwitterIcon />
                            </TwitterShareButton>
                            
                            <LinkedinShareButton url={pageUrl} className="share_btn" >
                                <LinkedinIcon size={32} round={false} />
                            </LinkedinShareButton>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    return(
        <div id="page_content" className="single_condition single-second-level-page">
            <div className="site_container" >
                { (singleCondition && singleCondition.length > 0) ? 
                    (
                        <ConditionMainContent />
                    ) : (
                        <div className="condition_section">
                            <Row>
                                <Col md={12} lg={6} >
                                    <Skeleton width="40%" height={40} />
                                    <Skeleton width="60%" height={50}/>
                                    <Skeleton variant="rect" width="100%" height={145} />
                                </Col>

                                <Col md={12} lg={6} >
                                    <Skeleton variant="rect" width="100%" height={235} />
                                </Col>
                            </Row>
                        </div>
                    )
                }

                { (singleCondition && singleCondition.length > 0) ? 
                    (
                        QuestionList
                    ) : (
                        <div className="skeleton">
                            <Skeleton width="100%" height={74} />
                            <Skeleton width="100%" height={74} />
                            <Skeleton width="100%" height={74} />
                        </div>
                    )
                }

                { (singleCondition && singleCondition.length > 0) ? 
                    (
                        <ContributedNote />
                    ) : (
                        <div className="skeleton"><Skeleton width="50%" /></div>
                    )
                }
                                
            </div>
            <GlobalCTA />
        </div>
    );
}