import React from 'react'
import { useLocation } from "react-router"
// import components
import Button from '@material-ui/core/Button'
import queryString from 'query-string'
export default function GeneralInfo(props) {
    const { PracticeName, distance, Address1, Address2,
        City, State, Postcode, rank, searchSpecialist} = props
    const location = useLocation()
    const inputQueries = queryString.parse(location.search)
    
    const practiceURL = "/find-detail?PracticeID="+props.id+"&language="+inputQueries.language+"&Clinical="+inputQueries.clinical+"&searchSpecialist="+inputQueries.searchSpecialist+"&page="+inputQueries.page
    
    return (
        <div className="general_info">
            <h3 className="name"><a href={practiceURL}>{PracticeName}</a></h3>
            {
                rank ==="1" && searchSpecialist!=="" &&
                <div className="spIcons"><p className="SW">S</p><p class="TW">T</p></div>
            }
            {
                rank ==="2" && searchSpecialist!=="" &&
                <div className="spIcons"><p className="SW">S</p></div>
            }
             {
                rank ==="3" && searchSpecialist!=="" &&
                <div className="spIcons"><p className="TW">T</p></div>
            }
            <span className="distance"><span className="label">Distance:</span> {distance}</span>
            <div className="address">
                { Address1 !== null && Address1 !== "" ? 
                    (
                        <span>{Address1}</span>
                    ) : (
                        <span>{Address2}</span>
                    )
                }, {City} {State} {Postcode}
            </div>
            <Button className="readmore" 
                color="primary"
                //href={`/find-detail?PracticeID=${id}`}
                href= {practiceURL}
                
                rel="noreferrer noopener"
            >
                Visit Practice
            </Button>
        </div>
    )
}