import React, { useCallback, useState, useEffect } from 'react';
import {
  Typeahead,
  Highlighter,
  Menu,
  MenuItem,
} from 'react-bootstrap-typeahead';
//import { render } from 'react-dom';
import List from 'react-tiny-virtual-list';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import StatePostCode from './States.json';
//icons
import { Search } from '@material-ui/icons'
const StatePostcodeOutput = StatePostCode.map(t => `${t.toString()}`);

export default function EnterLocation(props) {

    const [isLoaded, setIsLoaded] = useState(true);
    const [typeaheadState, setTypeHeadState] = useState({
        disabled: false       
    })
    const [labelText, setLabelText]=useState("Enter your suburb or postcode")
    
    useEffect(() => {
        return () => {
            setIsLoaded(false)
        }
    }, [])

    useEffect(()=>{
        if(props.inputLocation!==""){
            setLabelText(props.inputLocation)
        }
        else{
            setLabelText("Enter your suburb or postcode")
        }
    },[props])
    useEffect(()=>{
        setTypeHeadState({
            ...typeaheadState,
            disabled: props.locationDis
        })
    },[props])
    const renderMenu = useCallback((results, menuProps, props) => {
        const itemHeight = 32;
        return (
            <Menu {...menuProps}>
            {  results.length!==0 &&
                <List
                scrollToIndex={props.activeIndex || 0}
                scrollToAlignment="auto"
                height={results.length < 5 ? results.length * itemHeight : 300}
                itemCount={results.length}
                itemSize={itemHeight}
                renderItem={({ index, style }) => {
                    const item = results[index];
                    return (
                    <MenuItem key={item} option={item} position={index} style={style}>
                        <Highlighter search={props.text}>{item}</Highlighter>
                    </MenuItem>
                    );
                }}
            />
           
            }
                
            </Menu>
             
        );
  });

  return (
    <div className="form_row enter_location">
        {isLoaded &&
            <Typeahead
                {...typeaheadState}
                id="location_typeahead"
                className="input_location"
                maxResults={20}
                options={StatePostcodeOutput}
                onChange = {props.handleSelect}
                paginate={false}
                placeholder={props.locationDis ? "Location disabled":labelText } 
                minLength={2}
                highlightOnlyResult={true}
                renderMenu={renderMenu}
                selected={StatePostcodeOutput.slice(0, props.index)}
              
            />
           
           
        }
        <Search />
    </div>
  );
};