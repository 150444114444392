import React, {useState, useEffect} from 'react'
import ReactDOMServer from 'react-dom/server'
import queryString from 'query-string'
import { useLocation } from "react-router"
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api'
import {mainMarkerIcon} from '../components/findaphysio/searchResult/mapElements/MarkerIcon'

import { configPara } from '../components/globalComponents/APIUrlConfig'

// import child components
import ContactInfo from '../components/findaphysio/searchResult/practiceDetails/ContactInfo'

// import components
import Button from '@material-ui/core/Button'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

// import style
import './SinglePhysio.sass'

export default function SinglePhysio() {
    const getPracticeApiUrl = configPara.url.concat('/fapdetail_rest_api')
    const headers = {
        'Content-Type': 'application/json'
    }

    const [physio, setPhysio] = useState(null)
    const [physioLocation, setPhysioLocation] = useState(null)

    let history = useHistory()
    const location = useLocation()
    const inputQueries = queryString.parse(location.search)
    const [isPracticeLoaded, setIsPracticeLoaded] = useState(false)

    const searchQuery = {
        id: inputQueries.PracticeID,
        userName: null,
        language: inputQueries.language,
        Clinical: inputQueries.Clinical,
        searchSpecialist: inputQueries.searchSpecialist

    }

    useEffect(() => {
        if (inputQueries.PracticeID === undefined || inputQueries.PracticeID === ''){
            history.push('/find-a-physio')
        } else {
            axios.post(getPracticeApiUrl, searchQuery, {
                timeout: 10000,
                headers: headers, 
                auth: configPara.auth
            })
            .then(res => {
                const location = res.data.Destination.split(',')
                setPhysioLocation({
                    lat: parseFloat(location[0]),
                    lng: parseFloat(location[1])
                })
                setPhysio(res.data)
                setIsPracticeLoaded(true)
                
            })
            .catch( _ => {
                console.log('error')
            })
        }
    }, [inputQueries,getPracticeApiUrl, searchQuery,headers, history ])

    // convert svg to base64 string url for map marker icon
    const encodeSvg = (reactElement) => {
        return 'data:image/svg+xml,' + escape(ReactDOMServer.renderToStaticMarkup((reactElement)));
    }

    return (
        <div id="page_content" className="inner_gap single-physio">
            <div id="physio-map">
                { isPracticeLoaded ? (
                    <LoadScript
                        id="script-loader"
                        googleMapsApiKey="AIzaSyBUXY9mb7uoQp8PtmLH8tNkLvr7Vdm6xAQ"
                    >
                        <GoogleMap id="map"
                            zoom={16}
                            center={physioLocation}
                        >
                            <Marker className="marker"
                                position={physioLocation}
                                icon={encodeSvg(mainMarkerIcon)}
                            />
                        </GoogleMap>
                    </LoadScript>
                ) : (
                    <div className="map_loading">
                        <span className='outer_spinner'></span>
                        <span className='inner_spinner'></span>
                    </div>
                )}
            </div>
            <div className="site_container">
                {isPracticeLoaded &&
                    <div id="physio-info">
                        <h1 className="physio-name">{physio.PracticeName}</h1>

                        <div className="practice-full-details">
                            <div className="practice-general">
                                <div className="general_info">
                                    <div className="address">
                                        {physio.Address}<br /> {physio.Address2}
                                    </div>
                                </div>

                                <ContactInfo
                                    Phone={physio.Phone}
                                    Email={physio.Email}
                                    Website={physio.Website}
                                    Lat={physioLocation.lat}
                                    Lon={physioLocation.lng} />
                            </div>

                            <div className="practice-details">
                                <div className="column">
                                  {(physio.Services.EPC !== null || physio.Services.REBATE !== null || physio.Services.DVA !== null || physio.Services.COMP !== null || physio.Services.MOTOR !== null || physio.Services.HICAPS !== null || physio.Services.NDIS !== null || physio.Services.Telehealth !== null) &&
                                    <h4 className="label">Services</h4>
                                  }
                                    <ul className="services">
                                        {physio.Services.EPC !== null &&
                                            <li>{physio.Services.EPC}</li>
                                        }
                                        {physio.Services.REBATE !== null &&
                                            <li>{physio.Services.REBATE}</li>
                                        }
                                        {physio.Services.DVA !== null &&
                                            <li>{physio.Services.DVA}</li>
                                        }
                                        {physio.Services.COMP !== null &&
                                            <li>{physio.Services.COMP}</li>
                                        }
                                        {physio.Services.MOTOR !== null &&
                                            <li>{physio.Services.MOTOR}</li>
                                        }
                                        {physio.Services.HICAPS !== null &&
                                            <li>{physio.Services.HICAPS}</li>
                                        }
                                        {physio.Services.NDIS !== null &&
                                            <li>{physio.Services.NDIS}</li>
                                        }
                                        {physio.Services.Telehealth !== null &&
                                            <li>{physio.Services.Telehealth}</li>
                                        }
                                    </ul>
                                </div>
                                { physio.Users.length > 0 &&
                                    <div className="column">
                                        <h4 className="label">Physios</h4>
                                        <ul className="physios">
                                            {physio.Users.map(user =>
                                                <li key={user.ID} className="physioName">{user.UserName}
                                                    { inputQueries.Clinical !=="" &&  user.clinicalArea.length>0 &&
                                                         <div><span className="clinicalTitle">Special interest area</span>
                                                         <ul className="clinicalList">
                                                         {user.clinicalArea.map(clinical =><li key={clinical.ID} className={clinical.ID === parseInt(inputQueries.Clinical) || (parseInt(clinical.ID)===21 && parseInt(inputQueries.Clinical)===22) ? "activeClinical" : ""}>{clinical.Name}
                                                             </li>)
                                                         }
                                                         </ul>
                                                         </div>
                                                    }
                                                     { inputQueries.language !==""  &&  user.language.length>0 &&  
                                                         <div><span className="clinicalTitle">Language</span>
                                                         <ul className="clinicalList">
                                                         {user.language.map(language =><li key={language.ID} className={language.ID === parseInt(inputQueries.language) ? "activeClinical" : ""}>{language.Name}
                                                             </li>)
                                                         }
                                                         </ul>
                                                         </div>
                                                    }
                                                    {   physio.rank!=="5" && user.Specialisation.length>0 && 
                                                        <ul className="spcialList">
                                                        {user.Specialisation.map(special =><li key={special.ID}><div className="spDetailIcons"><p className="SW">S</p><p className="specialName">{special.Name}</p><p className="specialDes">(as awarded by the Australian College of Physiotherapists in {special.DateAwarded}).</p></div>
                                                            </li>)
                                                        }
                                                        </ul>
                                                    }
                                                    {   physio.rank!=="5" && user.TitledList.length>0 && 
                                                        <ul className="titleList">
                                                        {user.TitledList.map(Titled =><li key={Titled.ID}><div className="titleDetailIcons"><p className="TW">T</p><p>{Titled.Name}</p></div>
                                                            </li>)
                                                        }
                                                        </ul>
                                                    }
                                                    

                                                       
                                                </li>
                                           
                                            )}
                                        </ul>
                                       
                                    </div>
                                    
                                }
                            </div>
                            {/* <a href="/find-a-physio" style={{textDecoration: 'none'}}> */}
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="large"
                                    onClick={history.goBack}
                                    className="back-to-search"
                                    startIcon={<ChevronLeftIcon />}
                                >
                                    Back to result
                                </Button>     
                            {/* </a> */}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
