const initialState = {
    visibility: true,
    showContent: false
}

const SHOW_FULLSCREEN_FEATURED = "SHOW_FULLSCREEN_FEATURED";
const HIDE_FULLSCREEN_FEATURED = "HIDE_FULLSCREEN_FEATURED";
const SHOW_CONTENT = "SHOW_CONTENT";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_FULLSCREEN_FEATURED: {
            return {...state, visibility: true}
        }
        case HIDE_FULLSCREEN_FEATURED: {
            return {...state, visibility: false, showContent: true}
        }
        case SHOW_CONTENT: {
            return {...state, showContent: true}
        }
        default:
            return state;
    }
}