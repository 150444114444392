import axios from 'axios'
import { configPara } from '../../components/globalComponents/APIUrlConfig'

const CLEAR_DATA = "CLEAR_DATA";
const LOAD_TELEHEALTH = "LOAD_TELEHEALTH";
const apiUrl = configPara.url.concat('/custom_rest_api/content_resource/physio/telehealth/tele');

export const clearData = (payload) => {
    return {
        type: CLEAR_DATA,
        payload
    }
}

export const telehealth = (payload) => {
    return {
        type: LOAD_TELEHEALTH,
        payload
    }
};

export const fetchTelehealth = () => {
    return (dispatch) => {
        axios.get(apiUrl, {auth: configPara.auth})
            .then(response => {
                dispatch(telehealth(response.data))
            })
            .catch(error => {
                throw(error);
            });
    };
};