import React from 'react'

// elements
import Button from '@material-ui/core/Button'

export default function PracticeDisplayLayoutOption(props) {
    return (
        <React.Fragment>
            <Button className={!props.isGrid ? "display_layout row active" : "display_layout row"}
                onClick={() => props.setIsGrid(false)} >
                <span></span>
                <span></span>
                <span></span>
            </Button>
            <Button className={props.isGrid ? "display_layout column active" : "display_layout column"}
                onClick={() => props.setIsGrid(true)} >
                <span className="divider">
                    <span></span>
                    <span></span>
                </span>
                <span className="divider">
                    <span></span>
                    <span></span>
                </span>
            </Button>
        </React.Fragment>
    )
}