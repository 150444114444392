import React from 'react'
import { Link } from 'react-router-dom'
// components
import BackToPrevBtn from '../components/globalComponents/BackToPrevBtn'
import SeoMetaTags from '../components/globalComponents/SeoMetaTags'
// images

import backImg from './images/JOINT-PAIN-1920X1060.jpg';

const links = [
    { path: "/your-body/ankle/ankle-sprain", label: "Ankle sprain" },
    { path: "/your-lifestage/ageing-well/arthritis", label: "Arthritis" },
    { path: "/your-body/foot/conditions-big-toe", label: "Conditions of the big toe" },
    { path: "/your-body/shoulder/frozen-shoulder", label: "Frozen shoulder" },
    { path: "/your-body/knee/patellofemoral-pain-knee-cap-pain", label: "Knee cap pain" },
    { path: "/your-body/hand/osteoarthritis", label: "Osteoarthritis" },
    { path: "/your-condition/rheumatoid-arthritis", label: "Rheumatoid arthritis" },
    { path: "/your-body/elbow/tennis-elbow", label: "Tennis elbow" }
  ];

export default function PainJoin(){
        const backgroundImg = backImg;
        const metaTitle = "Your body | Choose physio"
        const metaDescription = "Find a physio. Your GP's most recommended health professional. Choose physio to help you recover from injury, reduce pain and stiffness, increase mobility and prevent further injury."

        return(
            <div id="page_content" className="inner_gap pain-join">
                <SeoMetaTags 
                    title={metaTitle} 
                    description={metaDescription} 
                    twitterImg="" 
                    ogImg="" />

                <div className="fullWidth_container full_height img_background" style={{backgroundImage: `url(${backgroundImg})` }}>
                    <div className="site_container">
                        <h1 className="accent_primary_heading left lg">
                            <span className="sub">Choose physio</span> 
                            <br/> for joint pain
                        </h1>
                        
                        <h3 className="sub_heading bright sm_top_gap">Choose the area you need help with:</h3>
                        <div className="btn_grid condition_grid col_4"> 
                        {links.map((link, index) => (
                            <Link key={index} to={link.path} className="grid_column">
                            {link.label}
                            </Link>
                        ))}
                        </div>
                        <BackToPrevBtn />
                    </div>  
                </div>
            </div>
        );
   
}