import React from 'react'

// import child components
//import LanguageSelectionField from './LanguageSelectionField'
import SearchSpecialistField from './SearchSpecialistField'

export default function SearchBySpecialist(props) {
    return (
        <div className="form_row">
            
            <SearchSpecialistField 
                SpecialistAreaRef={props.SpecialistAreaRef}
                selectedSpecialistArea={props.selectedSpecialistArea}
                handleSelectedSpecialistArea={props.handleSelectedSpecialistArea}
                specialistAreas={props.specialistAreas} />
        </div>
    )
}