import React, { Component } from 'react'
import { Link } from 'react-router-dom'

// css
import './GlobalCTA.sass'

// icons
import { Home } from '@material-ui/icons'

// images
import BackgroundImg from './images/friendly-physio-1394X900.jpg'

export default class GlobalCTA extends Component{
    render() {
        return(
            <div id="condition_cta" className="fullWidth_container img_background" 
                style={{ backgroundImage: `url(${BackgroundImg})` }}>
                <div className="site_container">
                    <h2 className="heading">
                        You can choose <br />your physio today
                    </h2>
                    <h3 className="sub_heading">
                        Visit <Link to={'/find-a-physio'}>findaphysio</Link> to find <br />
                        the right physiotherapist <br />
                        for you in your area.
                    </h3>

                    <Link to={'/find-a-physio'} className="icon_box">
                        <Home className="icon" />
                        <span className="icon_title">
                            Find a physio
                        </span>
                    </Link>
                </div>
            </div>
        );
    }
}