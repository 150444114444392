import React from 'react'

import Skeleton from '@material-ui/lab/Skeleton'

export default function PracticeSkeleton() {
    return (
        <React.Fragment>
            <div className="practice skeleton">
                <Skeleton />
                <Skeleton width="30%" />
                <Skeleton width="50%" />
                <Skeleton width="30%" />
                <Skeleton variant="rect" width="100%" height={178} />
            </div>
            <div className="practice skeleton">
                <Skeleton />
                <Skeleton width="30%" />
                <Skeleton width="50%" />
                <Skeleton width="30%" />
                <Skeleton variant="rect" width="100%" height={178} />
            </div>
            <div className="practice skeleton">
                <Skeleton />
                <Skeleton width="30%" />
                <Skeleton width="50%" />
                <Skeleton width="30%" />
                <Skeleton variant="rect" width="100%" height={178} />
            </div>
        </React.Fragment>
    )
}
