import React from 'react'

export const mainMarkerIcon =
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.533 512.533" width="30pt" height="30pt">
        <path style={{fill: '#ffa02e'}} d="M406.6,62.4c-83.2-83.2-217.6-83.2-299.733,0c-83.2,83.2-83.2,216.533,0,299.733l149.333,150.4  L405.533,363.2C488.733,280,488.733,145.6,406.6,62.4z"/>
        <path style={{fill: '#ffffff'}} d="M256.2,363.2c-83.2,0-150.4-67.2-150.4-150.4S173,62.4,256.2,62.4s150.4,67.2,150.4,150.4  S339.4,363.2,256.2,363.2z"/>
        <g>
            <path style={{fill: '#435B6C'}} d="M256.2,235.2c-41.6,0-77.867,19.2-102.4,49.067c22.4,32,59.733,53.333,102.4,53.333   s80-21.333,102.4-53.333C334.066,254.4,297.8,235.2,256.2,235.2z"/>
            <circle style={{fill: '#435B6C'}} cx="256.2" cy="149.867" r="53.333"/>
        </g>
    </svg>

export const relatedMarkerIcon =
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-74 0 512 512.0005" width="15pt" height="15pt">
        <path style={{fill: '#ffa02e'}} d="m182.441406 89.757812c-51.105468 0-92.683594 41.578126-92.683594 92.683594 0 51.105469 41.578126 92.683594 92.683594 92.683594 51.089844 0 92.65625-41.578125 92.65625-92.683594 0-51.105468-41.566406-92.683594-92.65625-92.683594zm0 0"/>
        <path style={{fill: '#ffa02e'}} d="m182.441406 0c-100.597656 0-182.441406 81.84375-182.441406 182.441406 0 6.988282.503906 14.375 1.535156 22.574219 6.242188 56.738281 36.839844 124.941406 90.949219 202.726563 39.863281 57.300781 77.394531 97.78125 78.96875 99.476562 5.9375 6.375 16.035156 6.375 21.976563.003906 1.578124-1.699218 39.199218-42.246094 79.109374-99.621094 54.179688-77.882812 84.742188-146.152343 90.84375-202.914062 1.011719-8.195312 1.503907-15.484375 1.503907-22.28125-.035157-100.578125-81.878907-182.40625-182.445313-182.40625zm0 305.152344c-67.660156 0-122.707031-55.046875-122.707031-122.710938 0-67.660156 55.046875-122.707031 122.707031-122.707031 67.648438 0 122.679688 55.046875 122.679688 122.707031 0 67.664063-55.03125 122.710938-122.679688 122.710938zm0 0"/>
    </svg>