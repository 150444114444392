import React from 'react'
import { InfoWindow } from '@react-google-maps/api'

export default function MarkerInfoWindow(props) {
    const {PracticeName, Address2, City, State, Postcode, Phone} = props.practice;
    return (
        <InfoWindow
            position={props.location}
            onCloseClick={props.handleClosePracticeInfo} 
        >
            <div className="info_window">
                <h4>{PracticeName}</h4>
                <p><span className="label">Address: </span>{Address2}, {City} {State} {Postcode}</p>
                <p><span className="label">Phone: </span>{Phone}</p>
            </div>
        </InfoWindow>
    )
}
