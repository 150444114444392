import axios from 'axios'
import { configPara } from '../../components/globalComponents/APIUrlConfig'
const LOAD_CONDITION_ITEMS = "LOAD_CONDITION_ITEMS";
const apiUrl = configPara.url.concat('/yourconditionalview');


export const yourConditionItems = (payload) => {
    return {
        type: LOAD_CONDITION_ITEMS,
        payload
    }
};

export const fetchYourCondition = () => {
    return (dispatch) => {
        axios.get(apiUrl, {auth: configPara.auth})
            .then(response => {
                dispatch(yourConditionItems(response.data))
            })
            .catch(error => {
                throw(error);
            });
    };
};