import React, { useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Skeleton from '@material-ui/lab/Skeleton'

// components
import BackToPrevBtn from '../components/globalComponents/BackToPrevBtn'
import SeoMetaTags from '../components/globalComponents/SeoMetaTags'

// css
import './YourWellbeing.sass'
import { hideFullscreenFeatured } from '../redux/actions/fullscreenFeaturedAction'
// images
import { fetchAllBlock } from '../redux/actions/fetchAllBlockAction'
import { fetchMyWellbeing } from '../redux/actions/fetchMyWellbeingAction'

export default function YourWellbeing() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(hideFullscreenFeatured());
        }, [dispatch])
    useEffect(() => {
        dispatch(fetchAllBlock());
    }, [dispatch]);
    useEffect(() => {
        dispatch(fetchMyWellbeing());
    }, [dispatch]);

    const Blocks = useSelector(state => state.fetchAllBlock.conditionBlocks);
    const conditionItems = useSelector(state => state.fetchMyWellbeing.myWellbeingItems);

    const pageUrl = window.location.pathname;
    const blocktitle = Blocks.filter(conditionBlock=>conditionBlock.field_pageurl === pageUrl).map(conditionBlock =>  conditionBlock.title);
    const backgroundImg = Blocks.filter(conditionBlock=>conditionBlock.field_pageurl === pageUrl).map(conditionBlock => conditionBlock.field_background_image);
    
    const metaTitle = "Your wellbeing | Choose physio"
    const metaDescription = "Physio can help you maximise your potential to live a happy and healthy life."

    const mappedConditionItems = conditionItems.map(conditionItem => 
        <Link key={conditionItem.nid} to={conditionItem.url} className="grid_column">{conditionItem.title}</Link>
    )

    return(
        <div id="page_content" className="inner_gap your-wellbeing">
            <SeoMetaTags 
                title={metaTitle} 
                description={metaDescription} 
                twitterImg="" 
                ogImg="" />

            <div className="fullWidth_container full_height img_background" style={{backgroundImage: `url(${backgroundImg})` }}>
                <div className="site_container">
                    <h1 className="accent_primary_heading left lg">
                        <span className="sub">Choose physio</span> 
                        <br/> for {blocktitle}
                    </h1>
                    <h3 className="sub_heading bright sm_top_gap">Choose the area you need help with:</h3>
                    <div className="btn_grid condition_grid col_2">
                        { conditionItems && conditionItems.length > 0 ?
                        (
                            mappedConditionItems
                        ) : (
                            <div className="skeleton">
                                <Skeleton variant="rect" height={52} />
                                <Skeleton variant="rect" height={52} />
                                <Skeleton variant="rect" height={52} />
                                <Skeleton variant="rect" height={52} />
                                <Skeleton variant="rect" height={52} />
                                <Skeleton variant="rect" height={52} />
                                <Skeleton variant="rect" height={52} />
                                <Skeleton variant="rect" height={52} />
                            </div>
                        )}
                    </div>
                    <BackToPrevBtn />
                </div>  
            </div>
        </div>
    );
    
}