import Geocode from "react-geocode"

import { setUserAddress } from './userInfoAction'

const GET_ADDRESS = "GET_ADDRESS"

export const fetchAddress = (payload) => {
    return {
        type: GET_ADDRESS,
        payload
    }
};

export const getGeoLocation = (lat, long) => {
    return (dispatch) => {
        
        return new Promise((resolve, reject) => {
            Geocode.setApiKey("AIzaSyBooZHR_M33b8q226HIf1rHAAoRzdc3tTo");
            Geocode.fromLatLng(lat, long).then(
                response => {
                    dispatch(fetchAddress(response.results[0]));
                    dispatch(setUserAddress(response.results[0].formatted_address));
                    resolve(response.results[0].formatted_address)
                })
                .catch(error => {
                    console.error(error);
                    reject()
                }
            );
        });
    };
};