import axios from 'axios'
import { configPara } from '../../components/globalComponents/APIUrlConfig'
const LOAD_SECOND_CONDITION = "LOAD_SECOND_CONDITION";
const CLEAR_STATE = "CLEAR_STATE";
const apiUrl = configPara.url.concat('/secondlevel_rest_api');

export const clearSecondLevelCondition = (payload) => {
    return {
        type: CLEAR_STATE,
        payload
    }
}

export const secondLevelCondition = (payload) => {
    return {
        type: LOAD_SECOND_CONDITION,
        payload
    }
};

export const fetchSecondLevelCondition = (slug) => {
    return (dispatch) => {
        axios.get(apiUrl+slug, {auth: configPara.auth})
            .then(response => {
                dispatch(secondLevelCondition(response.data))
            })
            .catch(error => {
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data)
                    console.log(error.response.status)
                    console.log(error.response.headers)
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request)
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message)
                }
                console.log(error.config)
                //reject("Error")
                //console.clear()
            })
    };
};