const initialState = {
    address: {}
}

const GET_ADDRESS = "GET_ADDRESS";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_ADDRESS: {
            return {...state,
                address: action.payload}
        }
        default:
            return state;
    }
}