const initialState = {
    userAddress: ''
}

const SET_USER_ADDRESS = "SET_USER_ADDRESS";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_USER_ADDRESS: {
            return {...state,
                userAddress: action.payload}
        }
        default:
            return state;
    }
}