import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from "react-router-dom"
import Skeleton from '@material-ui/lab/Skeleton'

// redux action
import { fetchAllGrids } from '../../redux/actions/physioCanTreatMainGridAction'


// css
import './MainGrid.sass'

export default function MainGrid(){
    const dispatch = useDispatch();
    useEffect( _ => {
        dispatch(fetchAllGrids());
    }, [dispatch])

    const grids = useSelector(state => state.physioCanTreatMainGrid.grids)
    const gridHover = event => {
        event.target.classList.add('hovered');
    }

    const gridNotHover = event => {
        event.target.classList.remove('hovered');
    }
    
    const GridsLayout = () => {
        return grids.map(grid => 
            <div className="grid" key={grid.nid} style={{backgroundImage: `url(${grid.field_physio_image})`, backgroundPosition: "center"}} >
                <Link className="grid-link" to={grid.url}
                    onMouseEnter={ event => gridHover(event)}
                    onMouseLeave={ event => gridNotHover(event)}>
                    <div className="grid_panel">
                        <h3 className="grid_title">{grid.field_physio_help_title}</h3>
                        <p className="grid_content">{grid.body}</p>
                        <div className="grid_cta">
                            <span>Find out more</span>
                        </div>

                    </div>
                </Link>
            </div>
        )
    }
    
    return (
        <div id="main_grid">
          
            { (grids && grids.length > 0) ? 
                (
                   
                    <GridsLayout />
                   
                ) : (
                    <div className="skeleton">
                        <Skeleton variant="rect" width="calc(50% - 1px)" height={250} />
                        <Skeleton variant="rect" width="calc(50% - 1px)" height={250} />
                        <Skeleton variant="rect" width="calc(50% - 1px)" height={250} />
                        <Skeleton variant="rect" width="calc(50% - 1px)" height={250} />
                    </div>
                )
            }
        </div>
    )
}