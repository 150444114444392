const currentUrl = window.location.href;

const configPara = {
  url: (currentUrl.includes("uat") || currentUrl.includes("localhost")) ? 'https://contentuat.choose.physio' : 'https://content.choose.physio',
  auth: {
    username: 'api',
    password: 'apaapi'
  }
};

export { configPara };