import React, {useState, useLayoutEffect} from 'react'
import ReactDOM from 'react-dom'
import { FormControl, InputLabel, Select, OutlinedInput } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function SearchByDistance(props) {
    const [distanceLabelWidth, setDistanceLabelWidth] = useState(0)

    useLayoutEffect(() => {
        setDistanceLabelWidth(ReactDOM.findDOMNode(props.distanceRef.current).offsetWidth)
    }, [props.distanceRef])

    return (
        <div className="form_row">
            <FormControl variant="outlined" className="input_distance">
                <InputLabel htmlFor="input_distance" 
                ref={props.distanceRef} >
                Select distance
                </InputLabel>
                <Select
                native
                IconComponent = {ExpandMoreIcon}
                value={props.selectedDistance}
                onChange={(e) => { props.handleSelectedDistance(e.target.value) }}
                input={
                    <OutlinedInput  name="input-distance" 
                                    labelWidth={distanceLabelWidth} 
                                    id="input_distance" />
                }
                >
                <option value="" />
                <option value={5}>Distance (5 km)</option>
                <option value={10}>Distance (10 km)</option>
                <option value={20}>Distance (20 km)</option>
                <option value={50}>Distance (50 km)</option>
                <option value={100}>Distance (100 km)</option>
                <option value={200}>Distance (200 km)</option>
                <option value={300}>Distance (300 km)</option>
                <option value="" />
                </Select>
            </FormControl>
        </div>
    )
}
