import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// components
import BackToPrevBtn from '../components/globalComponents/BackToPrevBtn'
import SeoMetaTags from '../components/globalComponents/SeoMetaTags'

// css
import './YourLifestage.sass'
import { hideFullscreenFeatured } from '../redux/actions/fullscreenFeaturedAction'
// images
import { fetchAllBlock } from '../redux/actions/fetchAllBlockAction'


const links = [
    { path: "/your-lifestage/infants-and-children", label: "Infants and children" },
    { path: "/your-lifestage/adolescents", label: "Adolescents" },
    { path: "/your-lifestage/adults", label: "Adults" },
    { path: "/your-lifestage/motherhood", label: "Motherhood" },
    { path: "/your-lifestage/ageing-well", label: "Ageing well" },
    { path: "/your-lifestage/motherhood/women’s-health", label: "Women's health" },
];

export default function YourLifestage(){
        const dispatch = useDispatch();
        useEffect(() => {
            dispatch(hideFullscreenFeatured());
            }, [dispatch])
        useEffect(() => {
            dispatch(fetchAllBlock());
        }, [dispatch]);
        const Blocks = useSelector(state => state.fetchAllBlock.conditionBlocks);
        const pageUrl = window.location.pathname;
        const blocktitle = Blocks.filter(conditionBlock=>conditionBlock.field_pageurl === pageUrl).map(conditionBlock =>  conditionBlock.title);
        const backgroundImg = Blocks.filter(conditionBlock=>conditionBlock.field_pageurl === pageUrl).map(conditionBlock => conditionBlock.field_background_image);
        const metaTitle = "Your lifestage | Choose physio"
        const metaDescription = "No matter your age, physio can be there for you along life’s journey."
        return(
            <div id="page_content" className="inner_gap your-lifestyle">
                <SeoMetaTags 
                    title={metaTitle} 
                    description={metaDescription} 
                    twitterImg="" 
                    ogImg="" />

                <div className="fullWidth_container full_height img_background" style={{backgroundImage: `url(${backgroundImg})` }}>
                    <div className="site_container">
                        <h1 className="accent_primary_heading left lg">
                            <span className="sub">Choose physio</span> 
                            <br/> for {blocktitle}
                        </h1>
                        <h3 className="sub_heading bright sm_top_gap">Choose the area you need help with:</h3>
                        <div className="btn_grid condition_grid col_2">
                        {links.map((link, index) => (
                            <Link to={link.path} className="grid_column" key={index}>
                                {link.label}
                            </Link>
                        ))}
                        </div>
                        <BackToPrevBtn />
                    </div>  
                </div>
            </div>
        );
    
}