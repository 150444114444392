import React, {useState} from 'react'

// elements
import Slider from '@material-ui/core/Slider'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'

export default function DistanceSliderSelectionField(props) {
    // DISTANCE HANDLERS
    // set distance element to state
    const [distanceAnchorEl, setDistanceAnchorEl] = useState(null)
    const openDistance = Boolean(distanceAnchorEl)
    const PopoverId = openDistance ? 'distance-popover' : undefined
    // open distance slider
    const handleDistanceOpen = event => {
        setDistanceAnchorEl(event.currentTarget)
    }
    // close distance slider
    const handleDistanceClose = () => {
        setDistanceAnchorEl(null)
    }
    // add 'km' to distance value
    const addDistanceType = (value) => {
        return `${value} km`
    }

    return (
        <React.Fragment>
            <Button
                className={openDistance ? 'distance_toggle active' : 'distance_toggle'}
                aria-describedby={PopoverId} 
                variant="outlined" 
                size="medium"
                onClick={handleDistanceOpen}
                disabled={props.isDataLoaded ? false : true} >
                    Within {props.distance} km
            </Button>
            <Popover
                className="distance_popover"
                id={PopoverId}
                open={openDistance}
                anchorEl={distanceAnchorEl}
                onClose={handleDistanceClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }} >
                    <Slider
                        className="distance_slider"
                        defaultValue={props.distance === '' ? 5 : props.distance}
                        getAriaValueText={addDistanceType}
                        valueLabelFormat={addDistanceType}
                        onChangeCommitted={(e, val) => {props.handleDistanceChange(val)}}
                        aria-labelledby="distance-slider"
                        valueLabelDisplay="auto"
                        step={5}
                        min={5}
                        max={300}
                        />
            </Popover>
        </React.Fragment>
    )
}
