import axios from 'axios'
import { configPara } from '../../components/globalComponents/APIUrlConfig'
const LOAD_All_BLOCK_ITEMS = "LOAD_All_BLOCK_ITEMS";
const apiUrl = configPara.url.concat('/getblock');


export const fetchAllBlockItems = (payload) => {
    return {
        type: LOAD_All_BLOCK_ITEMS,
        payload
    }
};

export const fetchAllBlock = () => {
    return (dispatch) => {
        axios.get(apiUrl, {auth: configPara.auth})
            .then(response => {
                dispatch(fetchAllBlockItems(response.data))
            })
            .catch(error => {
                throw(error);
            });
    };
};