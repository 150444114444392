import React, {useState, useLayoutEffect} from 'react'
import ReactDOM from 'react-dom'

import { FormControl, InputLabel, Select, OutlinedInput } from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

export default function SortingSelectionField(props) {
    const [sortByLabelWidth, setSortByLabelWidth] = useState(0)

    useLayoutEffect(() => {
        setSortByLabelWidth(ReactDOM.findDOMNode(props.sortByRef.current).offsetWidth)
    }, [props.sortByRef])

    return (
        <FormControl variant="outlined" className="sort_by" disabled={props.isDataLoaded ? false : true} >
            <InputLabel htmlFor="sort-by" 
            ref={props.sortByRef} >
            Sort by
            </InputLabel>
            <Select
            native
            IconComponent = {ExpandMoreIcon}
            value={props.sortOption}
            onChange={(e) => { props.handleSortByChange(e.target.value) }}
            input={
                <OutlinedInput name="sortby" labelWidth={sortByLabelWidth} id="sort-by" />
            }
            >   
                <option value="distance">Distance</option>
                <option value="ASC">Name Ascending</option>
                <option value="DESC">Name Descending</option>
            </Select>
        </FormControl>
    )
}
