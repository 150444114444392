import React, { useState, useCallback, useRef, useEffect } from 'react'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useLocation } from "react-router"
import queryString from 'query-string'

// import child components
import SearchByLocation from './searchOptions/SearchByLocation'
import SearchByDistance from './searchOptions/SearchByDistance'
import SearchByPractice from './searchOptions/SearchByPractice'
import SearchByPhysio from './searchOptions/SearchByPhysio'
import EnterLocation from './searchOptions/EnterLocation'
import SearchBySpecialist from './searchOptions/SearchBySpecialist'
// transition
import Collapse from '@material-ui/core/Collapse'

//components
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, 
    Typography, Paper, Tabs, Tab} from '@material-ui/core'
import { Button } from 'react-bootstrap'

//redux actions
import { getGeoLocation } from '../../redux/actions/getGeoLocationAction'
import { setResultsVisible } from '../../redux/actions/searchPracticeAction'

// css
import './SearchOptions.sass'

//icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
//call API
import axios from 'axios' 
import { configPara } from '../globalComponents/APIUrlConfig'

const AdhocBanner = () => {
    const [searchOptionsDesktop, setSearchOptionsDesktop] = useState('');
    const [searchOptionsMobile, setSearchOptionsMobile] = useState('');
    const [clickThru, setClickThru] = useState('#');
    const [clickTarget, setClickTarget] = useState('_self');
  
    useEffect(() => {
      fetch('/adhoc_banner/adhoc_banner_search.json')
        .then(response => response.json())
        .then(data => {
          setSearchOptionsDesktop(data.searchOptionsDesktop);
          setSearchOptionsMobile(data.searchOptionsMobile);
          setClickThru(data.clickThru);
          setClickTarget(data.clickTarget);
        })
        .catch(err => console.log(err));
    }, []);
    
    if( searchOptionsDesktop==="" || searchOptionsMobile==="" ){
        return <></>;
    }

    return (
        // href={clickThru} target={clickTarget} 
        <a className="adhoc-banner-link adhoc-search-options">
          <img src={searchOptionsMobile} alt="Banner" className="adhoc-banner-image adhoc-banner-mobile" />
          <img src={searchOptionsDesktop} alt="Banner" className="adhoc-banner-image adhoc-banner-desktop" />
        </a>
    );
  };

export default function SearchOptions(props){
    let history = useHistory()
	const dispatch = useDispatch()
    const locationSwitch = false
    // GET SEARCH QUERIES FROM URL
    const location = useLocation()
    const inputQueries = queryString.parse(location.search)

	// custom color checkbox
	const WhiteCheckbox = withStyles({
	    root: {
	      color: '#ffffff',
	      '&$checked': {
	        color: '#ffffff',
	      },
	    },
	    checked: {},
	})(props => <Checkbox color="default" {...props} />)
    const BlackCheckbox = withStyles({
	    root: {
	      color: '#000000',
	      '&$checked': {
	        color: '#000000',
	      },
	    },
	    checked: {},
	})(props => <Checkbox color="default" {...props} />)
	// initial location states
	const [userLocationData, setUserLocationData] = useState({
        useCurrentLocation: false,
        searchLocation: '',
        inputLocation: '',
        SuburbPost: '',
        lat: '',
        lng: '',
        inputPractice: '',
        inputPhysio: '',
        selectedDistance: '',
        selectedLanguage: '',
        selectedClinicalArea: '',
        refineSearchOption: 0,
        locationSupport: true,
        NDIS: "0",
        Telehealth: "0",
        NDISState: false,
        TelehealthState: false,
        selectedSpecialistArea: '',
        searchBySpecialistStatus: false, 
        page: 1
    })

    const [dataFromQueryLoaded, setDataFromQueryLoaded] = useState(false)
    useEffect( () => {
        if( (inputQueries.lat === '' || inputQueries.lng === '') && inputQueries.SuburbPost !==''){
            // when lat/long is empty, do something
            setUserLocationData({
                ...userLocationData,
                lat: '',
                lng: '',
                SuburbPost: inputQueries.SuburbPost,
                inputPractice: inputQueries.practice,
                inputPhysio: inputQueries.physio,
                selectedDistance: inputQueries.distance,
                selectedLanguage: inputQueries.language,
                selectedClinicalArea: inputQueries.clinical,
                selectedSpecialistArea: inputQueries.searchSpecialist,
                searchBySpecialistStatus: inputQueries.searchSpecialist!==""?true:false,
                page: inputQueries.page
        

            })
            setDataFromQueryLoaded(true)
        } else if (inputQueries.lat === undefined || inputQueries.lng === undefined) {
            // when lat/long is undefined, do something
        } else {
            // when lat and long is valid, set data for search query
            setUserLocationData({
                ...userLocationData,
                lat: inputQueries.lat,
                lng: inputQueries.lng,
                inputPractice: inputQueries.practice,
                inputPhysio: inputQueries.physio,
                selectedDistance: inputQueries.distance,
                selectedLanguage: inputQueries.language,
                selectedClinicalArea: inputQueries.clinical,
                selectedSpecialistArea: inputQueries.searchSpecialist,
                page: inputQueries.page
            })
            setDataFromQueryLoaded(true)
        }
    }, [ ])

    useEffect(() => {
        // if search query is available, load in searchResult component directly
        if( dataFromQueryLoaded ){
            if( userLocationData.lat !== '' && userLocationData.lat !== undefined ){
                handleSearchPractice()
            }
            if(userLocationData.SuburbPost !==''){
                handleSearchPractice()
            }
        }
    }, [])
    //get NDIS and Telehealth status
    const statusURL = configPara.url.concat('/ndisandtelehealth_rest_api')
    const [statusExtraOption, setStatusExtraOption] = useState({
        NDIS: "0",
        Telehealth: "0"
    })

    useEffect(() => {
        axios.get(statusURL,{auth: configPara.auth})
        .then(response =>{
            setStatusExtraOption(
            {
                NDIS: response.data.NDIS,
                Telehealth: response.data.Telehealth
             
            })
            
        })
        .catch(error =>console.log(error))
        
    }, [])
    // input location ref
    const LocationRef = useRef(null)
    // identify if the location valid before sending search query
    const [isLocationValid, setIsLocationValid] = useState(false)
    // disable the location
    const [locationDis, setLocationDis] = useState(false)

    const getLocation = () => {
        const geolocation = navigator.geolocation;
        
        if (!geolocation) {
            setUserLocationData({ ...userLocationData, locationSupport: false });
            LocationRef.current.focus();
            return;
        }
            
        const options = {
            enableHighAccuracy: false, // Set to true for higher accuracy if needed
            timeout: 5000, // Timeout after 5 seconds
            maximumAge: 0 // Prevents returning a cached position
        };

        geolocation.getCurrentPosition(
            (position) => {
                currentLocationHandler(position);
            },
            (error) => {
                console.log('Permission denied');
                setUserLocationData({ ...userLocationData, locationSupport: false });
                LocationRef.current.focus();
            },
            options
        );
    };
    
    
    // get and set address from current location
    const currentLocationHandler = useCallback(async (position) => {
        const lat = position.coords.latitude;
        const long = position.coords.longitude;
    
        if (userLocationData.useCurrentLocation) { // if "Using current location" is currently ticked
            setUserLocationData(prevState => ({
                ...prevState,
                useCurrentLocation: false,
                inputLocation: '',
                lat: '',
                lng: ''
            }));
            setIsLocationValid(false);
        } else { // if "Using current location" is NOT currently ticked
            try {
                const userAddress = await dispatch(getGeoLocation(lat, long));
                setUserLocationData(prevState => ({
                    ...prevState,
                    inputLocation: userAddress,
                    SuburbPost: '',
                    lat: lat,
                    lng: long,
                    useCurrentLocation: true
                }));
                setIsLocationValid(true);
                setIndex(0);
            } catch (error) {
                // Handle any errors during the getGeoLocation process
                console.error("Failed to retrieve location address", error);
            }
        }
    }, [dispatch, userLocationData]);
    
    

    // identify distance/language/clinical area select to handle transition on focus
	const DistanceRef = useRef(null)
    const LanguageRef = useRef(null)
    const ClinicalAreaRef = useRef(null)
    const SpecialistAreaRef = useRef(null)
    // handle location input change
    const handleInputLocation = (value) => {
        setUserLocationData({ 
            ...userLocationData,
            searchLocation: value,
            inputLocation: value,
            lat: '',
            lng: '',
            useCurrentLocation: false
        })
        setIsLocationValid(false)
    }

    // handle distance input change
    const handleSelectedDistance = (value) => {
        setUserLocationData({ 
            ...userLocationData, 
            selectedDistance: value })
    }

    // handle select language change
    const handleSelectedLanguage = (value) => {
        setUserLocationData({ 
            ...userLocationData, 
            selectedLanguage: value })
    }

    // handle select clinical area change
    const handleSelectedClinicalArea = (value) => {
        setUserLocationData({ 
            ...userLocationData, 
            selectedClinicalArea: value })
    }
    // handle select specialist area change
     const handleSelectedSpecialistArea = (value) => {
        setUserLocationData({ 
            ...userLocationData, 
            selectedSpecialistArea: value 
        })
    }
      // handle practice name change
    const handleInputPractice = (value) => {
        setUserLocationData({ 
            ...userLocationData, 
            inputLocation:"",
            inputPhysio: "",
            inputPractice: value
        })
        setIsLocationValid(true)
        value === "" ? setLocationDis(false) : setLocationDis(true)
    }
    // handle search by Specialist change
    const checkSearchBySpecialist = (value)=>{
        setUserLocationData({
            ...userLocationData,
            searchBySpecialistStatus: userLocationData.searchBySpecialistStatus===true?false:true,
           
            
        })
    }

    // handle physio name change
    const handleInputPhysio = (value) => {
        setUserLocationData({ 
            ...userLocationData, 
            inputLocation:"",
            inputPractice:"",
            inputPhysio: value
        })
        setIsLocationValid(true)
        value === "" ? setLocationDis(false) : setLocationDis(true)
    }

    // handle tabs switching (practice | physio) for refine search option
    const handleRefineSearchOption = (value) => {
        setUserLocationData({ 
            ...userLocationData, 
            refineSearchOption: value,
            searchBySpecialistStatus: value===2 || 5 ?true:false,

        })
        if(value===0||value===1 || value===3 || value===4){
            setUserLocationData({ 
                ...userLocationData, 
                refineSearchOption: value,
                selectedSpecialistArea: "" ,
                searchBySpecialistStatus: false
            })

        }
        
    }
    // handle geolocation when a suggest location is selected
    const handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
        setUserLocationData({ 
            ...userLocationData,
            searchLocation: '',
            inputLocation: geocodedPrediction.formatted_address,
            lat: geocodedPrediction.geometry.location.lat(),
            lng: geocodedPrediction.geometry.location.lng(),
            useCurrentLocation: false
        })
        // for location validation
        setIsLocationValid(true)
    }
    //NDIS checkbox handle change
    const handleNDIS =()=>{
        setUserLocationData({ 
            ...userLocationData,
            NDIS: userLocationData.NDIS ==="1"? "0":"1",
            NDISState: userLocationData.NDISState===true?false:true
        })
    }
    //Telehealth checkbox handle change
    const checkTele = ()=>{
        setUserLocationData({ 
            ...userLocationData,
            Telehealth: userLocationData.Telehealth ==="1"? "0":"1",
            TelehealthState: userLocationData.TelehealthState===true?false:true
        })
    }
    //handle location select
    const handleSelect = (selected)=>{
        setUserLocationData({ 
            ...userLocationData,
            inputLocation: selected,
            SuburbPost: selected,
            searchLocation: '',
            lat: '',
            lng: '',
            useCurrentLocation: false
        })
    }
    // submit search query and load in SearchResult component
    const handleSearchPractice = () => {
        if( (userLocationData.lat === '' && userLocationData.lng === '' && userLocationData.inputPractice ==='' && userLocationData.inputPhysio ==='' && userLocationData.SuburbPost ==='') 
            || (userLocationData.lat === undefined && userLocationData.lng === undefined  && userLocationData.inputPractice ==='' && userLocationData.inputPhysio ==='' && userLocationData.SuburbPost ==='')) {
            setIsSearchFailSubmit(true)
        }else if(userLocationData.searchBySpecialistStatus && userLocationData.selectedSpecialistArea===''){
            setIsSearchFailSubmit(true)
        }
        else{
            // load in searchResult component
            dispatch(setResultsVisible())
            // search query variables
            const practice = userLocationData.selectedSpecialistArea!==""?"":userLocationData.inputPractice
            const physio = userLocationData.selectedSpecialistArea!==""?"":userLocationData.inputPhysio
            const SuburbPost = userLocationData.SuburbPost
            const distance = userLocationData.selectedDistance
            const language = userLocationData.selectedSpecialistArea!==""?"":userLocationData.selectedLanguage
            const clinical = userLocationData.selectedSpecialistArea!==""?"":userLocationData.selectedClinicalArea
            const lat = userLocationData.lat
            const lng = userLocationData.lng
            const page = inputQueries.page ==="" || inputQueries.page===undefined ? 1 : inputQueries.page
            const sort = inputQueries.sort
            const NDIS = userLocationData.selectedSpecialistArea!==""?"":userLocationData.NDIS 
            const Telehealth = userLocationData.selectedSpecialistArea!==""?"":userLocationData.Telehealth 
            const searchSpecialist = userLocationData.selectedSpecialistArea
            let query = ''
            // identify if google map script is needed for SearchResult component
            if( dataFromQueryLoaded ){
                query = `?practice=${practice}&physio=${physio}&clinical=${clinical}&distance=${distance}&SuburbPost=${SuburbPost}&language=${language}&lat=${lat}&lng=${lng}&page=${page}&sort=${sort}&NDIS=${NDIS}&Telehealth=${Telehealth}&searchSpecialist=${searchSpecialist}&mapScriptRequired=true`
            } else {
                query = `?practice=${practice}&physio=${physio}&clinical=${clinical}&distance=${distance}&SuburbPost=${SuburbPost}&language=${language}&lat=${lat}&lng=${lng}&page=${page}&sort=${sort}&NDIS=${NDIS}&Telehealth=${Telehealth}&searchSpecialist=${searchSpecialist}&mapScriptRequired=false`
            }
            // push query to URL
            history.push(query)
        }
    }

    const [isSearchFailSubmit, setIsSearchFailSubmit] = useState(false)
    const [index, setIndex] = useState(0)
	return(
        <div className="fullWidth_container full_height img_background find-physio-bg">
            <div className="site_container">
                <h1 className="accent_primary_heading left lg bright">
                    Find a physio today
                </h1>
                <div id="location_options">
                    <FormControlLabel
                        className="current-location"
                        control={
                            <WhiteCheckbox checked={userLocationData.useCurrentLocation} onChange={getLocation} value="current-location" />
                        }
                        label="Using current location" 
                        disabled={locationDis}
                    />
                    <span className="word_bridge" >or</span>
                    <span className="label" >Enter a location below</span>
                </div>

                <Collapse in={!(userLocationData.locationSupport)}>
                    <span className="location_note">Unable to locate your address. Please fill in the location.</span>
                </Collapse>

                <form id="get_location" >
                    {
                        (locationSwitch)? 
                        (<SearchByLocation 
                        locationRef={LocationRef}
                        searchLocation={userLocationData.searchLocation}
                        inputLocation={userLocationData.inputLocation}
                        handleSelectSuggest={handleSelectSuggest}
                        isLocationValid={isLocationValid}
                        locationDis={locationDis}
                        isSearchFailSubmit={isSearchFailSubmit}
                        handleInputLocation={handleInputLocation} />)
                        :(
                            <EnterLocation 
                            locationDis={locationDis}
                            handleSelect={handleSelect}
                            inputLocation={userLocationData.inputLocation}
                            index = {index}
                        />
                       
    
                        )
                        
                    }
                    <span className="notificationText">{(!isLocationValid && isSearchFailSubmit)
                            ? "Enter your suburb or postcode" : ""}</span>
                    <SearchByDistance 
                        distanceRef={DistanceRef}
                        selectedDistance={userLocationData.selectedDistance}
                        handleSelectedDistance={handleSelectedDistance} />

                    <ExpansionPanel className="refine_search">
                        <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                        >
                        <Typography className="refine_search_label">Refine your search</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className="refine_your_search">
                            <div className="refine_search_options">
                          
                                <Paper square>
                              
                                    <Tabs value={userLocationData.refineSearchOption} onChange={(event, value) => { handleRefineSearchOption(value) }}>
                                                                       
                                        <Tab label="Practice" className="deskTopTab"/>
                                        <Tab label="Physiotherapist" className="deskTopTab"/>
                                        <Tab label="Titled/Specialist Physiotherapist" className="deskTopTab spTab"/>
                                        <Tab label="Practice" className="mobileTab"/>
                                        <Tab label="Physio" className="mobileTab"/>
                                        <Tab label="Specialist Physio" className="mobileTab spTab"/>
                                    </Tabs>  
                                
                                </Paper>
                              
                                {(userLocationData.refineSearchOption === 0  || userLocationData.refineSearchOption === 3) &&
                                    <div className="tab_container">
                                        <SearchByPractice 
                                            inputPractice={userLocationData.inputPractice}
                                            handleInputPractice={handleInputPractice}
                                            languageRef={LanguageRef}
                                            selectedLanguage={userLocationData.selectedLanguage}
                                            handleSelectedLanguage={handleSelectedLanguage}
                                            languages={props.languages} 
                                            clinicalAreaRef={ClinicalAreaRef}
                                            selectedClinicalArea={userLocationData.selectedClinicalArea}
                                            handleSelectedClinicalArea={handleSelectedClinicalArea}
                                            clinicalAreas={props.clinicalAreas} />

                                    </div>
                                }
                                {(userLocationData.refineSearchOption === 1  || userLocationData.refineSearchOption === 4) &&
                                    <div className="tab_container">
                                        <SearchByPhysio 
                                            inputPhysio={userLocationData.inputPhysio}
                                            handleInputPhysio={handleInputPhysio}
                                            languageRef={LanguageRef}
                                            selectedLanguage={userLocationData.selectedLanguage}
                                            handleSelectedLanguage={handleSelectedLanguage}
                                            languages={props.languages} 
                                            clinicalAreaRef={ClinicalAreaRef}
                                            selectedClinicalArea={userLocationData.selectedClinicalArea}
                                            handleSelectedClinicalArea={handleSelectedClinicalArea}
                                            clinicalAreas={props.clinicalAreas} />
                                    </div>
                                }
                                {(userLocationData.refineSearchOption === 2 || userLocationData.refineSearchOption === 5) &&
                                    <div className="tab_container" id="searchSP">
                                      <SearchBySpecialist 
                                       SpecialistAreaRef={SpecialistAreaRef}
                                        selectedSpecialistArea={userLocationData.selectedSpecialistArea}
                                        handleSelectedSpecialistArea={handleSelectedSpecialistArea}
                                        specialistAreas={props.specialistAreas} 
                                    /> 
                                    <div className="notificationSP">{(userLocationData.searchBySpecialistStatus && isSearchFailSubmit && userLocationData.selectedSpecialistArea==='')? "Please select one specialist area" : ""}</div> 
                                    <div className="searchTabSP"><div className="spInfo"><p className="SW">S</p>Specialist Physiotherapist</div><div className="specialistDescription">A Specialist Physiotherapist, as awarded by the Australian College of Physiotherapists, has completed additional training in a field of physiotherapy and has attained <strong>expert</strong> level of skill, clinical reasoning and evidence-based practice to provide optimal care.</div><div className="titleInfo"><p className="TW">T</p>Titled Physiotherapist</div><div className="specialistDescription">A Titled Physiotherapist, as awarded by the Australian College of Physiotherapists, has attained a <strong>highly developed</strong> level of skill, clinical reasoning and evidence-based practice in a field of Physiotherapy to provide care to all but the most complex client presentations.</div></div>
                                    
                                    </div>

                                }   
                                {  userLocationData.refineSearchOption !== 2 && userLocationData.refineSearchOption !== 5 &&
                                
                                <div id="extra-option">
                                    { statusExtraOption.Telehealth === 1 &&

                                    
                                    <FormControlLabel
                                        className=""
                                        control={
                                            <BlackCheckbox  checked={userLocationData.TelehealthState} onChange={checkTele} value="Tele" />
                                        }
                                        label="Telehealth - video consultation" 
                                     />
                                    }
                                    { statusExtraOption.NDIS === 1 &&
                                     <FormControlLabel
                                        className=""
                                        control={
                                            <BlackCheckbox checked={userLocationData.NDISState} onChange={handleNDIS} value="NDIS" />
                                        }
                                        label="NDIS" 
                                     />
                                    }
                                </div>
                                } 
                                {  userLocationData.refineSearchOption === 55 &&

                                
                                <div id="searchSP">
                                    <FormControlLabel
                                    className=""
                                    control={
                                        <BlackCheckbox  checked={userLocationData.searchBySpecialistStatus} onChange={checkSearchBySpecialist} value="specialSearch" />
                                    }
                                    label="Specialist physiotherapist" 
                                    />
                                    { userLocationData.searchBySpecialistStatus===true &&
                                       <SearchBySpecialist 
                                       SpecialistAreaRef={SpecialistAreaRef}
                                        selectedSpecialistArea={userLocationData.selectedSpecialistArea}
                                        handleSelectedSpecialistArea={handleSelectedSpecialistArea}
                                        specialistAreas={props.specialistAreas} 
                                    />
                                    }
                                                              
                                    <div className="notificationSP">{(userLocationData.searchBySpecialistStatus && isSearchFailSubmit && userLocationData.selectedSpecialistArea==='')? "Please select one specialist area" : ""}</div>
                                    
                                </div>
                                }
                                

                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <Button variant="primary" className="search_btn" 
                        onClick={ handleSearchPractice } >
                            Search</Button>
                </form>
                <AdhocBanner/>
            </div>  
        </div>
	)
}