import React from 'react'

export default function Pagination(props) {

    const PaginationList = () => {
        return (
            props.pagination.map(page => {
                let current = false
                if ( page.id === props.currentPage ) {
                    current = true
                }
                return (
                    <li className={ current ? "page_item current" : "page_item"} 
                        key={page.id} 
                        onClick={ () => props.handlePagination(page.id) } 
                    >
                        <span>{page.id}</span>
                    </li>
                )
            })
        )
    }

    return (
        <div className="search_pagination">
            <ul className="pagination_inner">
                <PaginationList />
                <li className="last"></li>
            </ul>
            <span className="fade_overlay"></span>
        </div>
    )
}
