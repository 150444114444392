import React, { Component } from 'react'
//import { Link } from 'react-router-dom'

// css
import './Footer.sass'

// bootstrap
import { Row, Col } from 'react-bootstrap'

// social icons
import { ReactComponent as Youtube } from './images/social_icon/youtube.svg'
import { ReactComponent as Facebook } from './images/social_icon/facebook.svg'
import { ReactComponent as Twitter } from './images/social_icon/twitter.svg'
import { ReactComponent as Linkedin } from './images/social_icon/linkedin.svg'
import { ReactComponent as Instagram } from './images/social_icon/instagram.svg'

export default class Footer extends Component{
    render() {
        const year = new Date().getFullYear();

        return(
            <div id="footer-container">
                <div className="site_container">
                    <Row>
                        <Col sm>
                            <p>Copyright © {year} <a href="https://australian.physio" target="_blank" rel="noopener noreferrer">Australian Physiotherapy Association</a>
                            </p>
                            <p><a href="https://australian.physio/legalstatement" target="_blank" rel="noopener noreferrer">Legal</a> | <a href="https://australian.physio/apa-privacy" target="_blank" rel="noopener noreferrer">Privacy </a> | <a href="mailto:info@australian.physio" target="_blank" rel="noopener noreferrer">Contact us</a></p>
                        </Col>
                        <Col sm>
                            <ul className="social_nav">
                                <li className="youtube"><a href="https://www.youtube.com/user/apatube1" target="_blank" rel="noopener noreferrer"><Youtube /></a></li>
                                <li className="facebook"><a href="https://www.facebook.com/AustralianPhysiotherapyAssociation" target="_blank" rel="noopener noreferrer"><Facebook /></a></li>
                                <li className="twitter"><a href="https://twitter.com/apaphysio" target="_blank" rel="noopener noreferrer"><Twitter /></a></li>
                                <li className="linkedin"><a href="https://www.linkedin.com/company/747310" target="_blank" rel="noopener noreferrer"><Linkedin /></a></li>
                                <li className="instagram"><a href="https://instagram.com/physioaustralia" target="_blank" rel="noopener noreferrer"><Instagram /></a></li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}
