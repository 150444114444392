const initialState = {
    searchResults: [],
    searchPara: [],
    searchEmpty: false,
    isResultsVisible: false,
    isSearchOptionsVisible: true
}

const SET_RESULT_VISIBLE = "SET_RESULT_VISIBLE";
const SET_RESULT_HIDDEN = "SET_RESULT_HIDDEN";
const SEARCH_PRACTICE = "SEARCH_PRACTICE";
const SEARCH_PARAMETERS = "SEARCH_PARAMETERS";
const SEARCH_EMPTY = "SEARCH_EMPTY";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_RESULT_VISIBLE: {
            return {
                ...state, 
                isResultsVisible: true,
                isSearchOptionsVisible: false
            }
        }
        case SET_RESULT_HIDDEN: {
            return {
                ...state, 
                isResultsVisible: false,
                isSearchOptionsVisible: true
            }
        }
        case SEARCH_PRACTICE: {
            return {...state,
                searchResults: action.payload}
        }
        case SEARCH_PARAMETERS: {
            return {...state,
                searchPara: action.payload}
        }
        case SEARCH_EMPTY: {
            return {...state,
                searchEmpty: action.payload}
        }
        default:
            return state;
    }
}