import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { withRouter } from 'react-router'

// icons
import { ChevronLeft } from '@material-ui/icons'

class BackToPrevBtn extends Component{
    constructor(props) {
        super(props)
    
        this.backToPrev = this.backToPrev.bind(this);
    }
    
    backToPrev() {
        this.props.history.goBack();
    }

    render() {
        return (
            <Button variant="primary" className={this.props.variant} onClick={this.backToPrev}>
                <ChevronLeft className="left_icon" /> 
                Back to previous
            </Button>
        )
    }
}

export default withRouter(BackToPrevBtn);