import React, {useState, useEffect} from 'react'
import axios from 'axios'

import './PracticeFullDetails.sass'

import { configPara } from '../../globalComponents/APIUrlConfig'

// import child components
import GeneralInfo from './practiceDetails/GeneralInfo'
import ContactInfo from './practiceDetails/ContactInfo'

// icons
import CloseIcon from '@material-ui/icons/Close'

export default function PracticeFullDetails(props) {
    const {isSelected, selectedId, userName, PracticeName, language, selectedPractice, searchSpecialist} = props

    const [practiceProfile, setPracticeProfile] = useState(null)

    const searchPracticeApiUrl = configPara.url.concat('/fapdetail_rest_api')
    const headers = {
        'Content-Type': 'application/json'
    }

    const [searchQuery, setSearchQuery] = useState({
        id: null,
        userName: null,
        language: null,
        Clinical: null,
        searchSpecialist: ''
    })

    useEffect(() => {
        setSearchQuery({
            id: selectedId,
            userName: userName,
            language: language,
            Clinical: PracticeName,
            searchSpecialist: searchSpecialist
        })
    }, [ selectedId, userName, language, PracticeName, searchSpecialist ])

    useEffect(() => {
        isSelected && axios.post(searchPracticeApiUrl, searchQuery, {
            timeout: 10000,
            headers: headers, 
            auth: configPara.auth
        })
            .then(response => {
                setPracticeProfile(response.data)
            })
            .catch(error => {
                if (error.response) {
                    /*
                        * The request was made and the server responded with a
                        * status code that falls out of the range of 2xx
                        */
                    //console.log(error.response.data)
                    //console.log(error.response.status)
                    //console.log(error.response.headers)
                } else if (error.request) {
                    /*
                        * The request was made but no response was received, `error.request`
                        * is an instance of XMLHttpRequest in the browser and an instance
                        * of http.ClientRequest in Node.js
                        */
                    console.log(error.request)
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message)
                }
                console.log(error.config)
                //console.clear()
            })
    }, [ searchQuery, headers, searchPracticeApiUrl, isSelected ]);

    return (
        <div className={isSelected ? "selected_practice_info active" : "selected_practice_info"} >
            { isSelected &&
                selectedPractice.map(practice => {
                    const {PracticeID, PracticeName, distance, Address1, Address2,
                        City, State, Postcode, Phone, Website, Lat, Lon} = practice
                    return(
                        <React.Fragment key={PracticeID}>
                            <span className="close_practice_info" 
                                onClick={props.handleClosePracticeInfo} >
                                    <CloseIcon />
                            </span>

                            <div className="practice-general">
                                <GeneralInfo 
                                    id={PracticeID}
                                    PracticeName={PracticeName}
                                    distance={distance}
                                    Address1={Address1}
                                    Address2={Address2}
                                    City={City}
                                    State={State}
                                    Postcode={Postcode} />

                                <ContactInfo
                                    Phone={Phone}
                                    Email={practice.Email}
                                    Website={Website}
                                    Lat={Lat}
                                    Lon={Lon} />
                            </div>
                            <div className="practice-full-details">
                                {practiceProfile &&
                                    <div className="column">
                                    {(practiceProfile.Services.EPC !== null || practiceProfile.Services.REBATE !== null || practiceProfile.Services.DVA !== null || practiceProfile.Services.COMP !== null || practiceProfile.Services.MOTOR !== null || practiceProfile.Services.HICAPS !== null || practiceProfile.Services.NDIS !== null || practiceProfile.Services.Telehealth !== null) &&
                                        <h4 className="label">Services</h4>
                                    }
                                        <ul className="services">
                                            {practiceProfile.Services.EPC !== null &&
                                                <li>{practiceProfile.Services.EPC}</li>
                                            }
                                            {practiceProfile.Services.REBATE !== null &&
                                                <li>{practiceProfile.Services.REBATE}</li>
                                            }
                                            {practiceProfile.Services.DVA !== null &&
                                                <li>{practiceProfile.Services.DVA}</li>
                                            }
                                            {practiceProfile.Services.COMP !== null &&
                                                <li>{practiceProfile.Services.COMP}</li>
                                            }
                                            {practiceProfile.Services.MOTOR !== null &&
                                                <li>{practiceProfile.Services.MOTOR}</li>
                                            }
                                            {practiceProfile.Services.HICAPS !== null &&
                                                <li>{practiceProfile.Services.HICAPS}</li>
                                            }
                                            {practiceProfile.Services.NDIS !== null &&
                                                <li>{practiceProfile.Services.NDIS}</li>
                                            }
                                            {practiceProfile.Services.Telehealth !== null &&
                                                <li>{practiceProfile.Services.Telehealth}</li>
                                            }
                                        </ul>
                                    </div>
                                }
                                { practiceProfile && practiceProfile.Users.length > 0 &&
                                    <div className="column">
                                        <h4 className="label">Physios</h4>
                                        <ul className="physios">
                                            {practiceProfile.Users.map(user => 
                                                <li key={user.ID}>{user.UserName}
                                                    {   practiceProfile.rank!=="5" && user.Specialisation.length>0 &&
                                                        <ul className="spcialList">
                                                        {user.Specialisation.map(special =><li key={special.ID}><div className="spDetailIcons"><p class="SW">S</p><p className="specialName">{special.Name}</p><p className="specialDes">(as awarded by the Australian College of Physiotherapists in {special.DateAwarded}).</p></div>
                                                            </li>)
                                                        }
                                                        </ul>
                                                    }
                                                    {   practiceProfile.rank!=="5" && user.TitledList.length>0 &&
                                                        <ul className="titleList">
                                                        {user.TitledList.map(Titled =><li key={Titled.ID}><div className="titleDetailIcons"><p class="TW">T</p><p>{Titled.Name}</p></div>
                                                            </li>)
                                                        }
                                                        </ul>
                                                    }
                                                </li>
                                                
                                            )}
                                        </ul>
                                    </div>
                                }
                            </div>
                                
                        </React.Fragment>
                    )
                })
            }
        </div>
    )
}

