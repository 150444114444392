const initialState = {
    conditionBlocks: []
}

const LOAD_All_BLOCK_ITEMS = "LOAD_All_BLOCK_ITEMS";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_All_BLOCK_ITEMS: {
            return {...state,
                conditionBlocks: action.payload}
        }
        default:
            return state;
    }
    
}