import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import SeoMetaTags from '../components/globalComponents/SeoMetaTags'

// redux action
import { fetchConditionThirdLevelItems, clearConditionItems } from '../redux/actions/conditionThirdLevelPageAction'

// components
import BackToPrevBtn from '../components/globalComponents/BackToPrevBtn'

// css
import './ConditionThirdLevelPage.sass'

// images
import { fetchAllBlock } from '../redux/actions/fetchAllBlockAction'
export default function ConditionThirdLevelPage(props) {
    
    const blockdispatch = useDispatch();
    useEffect(() => {
        blockdispatch(fetchAllBlock());
    }, [blockdispatch]);
    const Blocks = useSelector(state => state.fetchAllBlock.conditionBlocks);
    const pageUrl = window.location.pathname;
    const blocktitle = Blocks.filter(conditionBlock=>conditionBlock.field_pageurl === pageUrl).map(conditionBlock =>  conditionBlock.title);
    const backgroundImg = Blocks.filter(conditionBlock=>conditionBlock.field_pageurl === pageUrl).map(conditionBlock => conditionBlock.field_background_image);
    const dispatch = useDispatch();
    useEffect(() => {
        const slug = props.match.params.category;
        dispatch(fetchConditionThirdLevelItems(slug));
    }, [dispatch, props]);

    const conditionItems = useSelector(state => state.conditionThirdLevelPage.conditionItems);

    const mappedConditionItems = conditionItems.map(conditionItem => 
        <Link key={conditionItem.nid} to={conditionItem.url} className="grid_column">{conditionItem.title}</Link>
    )

    useEffect(() => {
        // clear out state before exit
        return () => {
            dispatch(clearConditionItems())
        };
    }, [dispatch])

	const metaTitle = "Find a physio - Choose physio | Australian Physiotherapy Association"
    const metaDescription = "Covering everything from stroke to incontinence, physio can help you live with a variety of conditions and diseases."

    return(
        <div id="page_content" className="inner_gap condition-third-level-page">
            <SeoMetaTags 
                title={metaTitle} 
                description={metaDescription} 
                twitterImg="" 
                ogImg="" />
            <div className="fullWidth_container full_height img_background" style={{backgroundImage: `url(${backgroundImg})` }}>
                <div className="site_container">
                    <h1 className="accent_primary_heading left lg">
                        <span className="sub">Choose physio</span> 
                        <br/> for {blocktitle}
                    </h1>
                    <h3 className="sub_heading bright sm_top_gap">Choose the area you need help with:</h3>
                    <div className="btn_grid condition_grid col_2">
                        {mappedConditionItems}
                    </div>
                    <BackToPrevBtn variant="lg_gap" />
                </div>  
            </div>
        </div>
    );
}