import React from 'react'

import { Marker } from '@react-google-maps/api'
import {mainMarkerIcon} from './MarkerIcon'
import MarkerInfoWindow from './MarkerInfoWindow'

export default function MainMarkers(props) {
    return (
        <React.Fragment>
            { props.allPractices.result.map( 
                practice => {
                    const {PracticeID} = practice
                    const location = {
                        lat: parseFloat(practice.Lat), 
                        lng: parseFloat(practice.Lon)
                    }
                    return(
                        <Marker className="marker"
                            key={PracticeID}
                            onLoad={() => {props.extentBoundsHandler(location)}}
                            position={location}
                            animation={window.google.maps.Animation.DROP}
                            icon={props.encodeSvg(mainMarkerIcon)}
                            //icon={ PracticeID === selectedPractice.selectedId ? MapMarkerActive : MapMarker }
                            onClick={() => {props.handleMarkerClick(PracticeID)}} >
                                {PracticeID === props.selectedPractice.selectedId && 
                                    <MarkerInfoWindow 
                                        location={location}
                                        handleClosePracticeInfo={props.handleClosePracticeInfo}
                                        practice={practice}
                                    />
                                }
                        </Marker>
                    )
                })
            }
        </React.Fragment>
    )
}
