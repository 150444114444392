import { combineReducers } from 'redux'

import fullscreenFeatured from './fullscreenFeaturedReducer'
import physioCanTreatMainGrid from './physioCanTreatMainGridReducer'
import whatIsPhysio from './whatIsPhysioReducer'
import fetchWhatIsPhysio from './fetchWhatIsPhysioReducer'
import conditionThirdLevelPage from './conditionThirdLevelPageReducer'
import singleCondition from './singleConditionReducer'
import getGeoLocation from './getGeoLocationReducer'
import userInfo from './userInfoReducer'
import fetchAllBlock from './fetchAllBlockReducer'
import fetchYourCondition from './fetchYourConditionReducer'
import fetchSecondLevelCondition from './singleSecondLevelReducer'
import fetchMyWellbeing from './fetchMyWellbeingReducer'
import contentSearch from './contentSearchReducer'
import searchPractice from './searchPracticeReducer'
import fetchAllShortenList from './fetchShortenUrlListReducer'
import fetchTelehealth from './fetchTelehealthReducer'
import adminLogin from './adminLoginReducer'

export default combineReducers({
    fullscreenFeatured,
    physioCanTreatMainGrid,
    whatIsPhysio,
    fetchWhatIsPhysio,
    conditionThirdLevelPage,
    singleCondition,
    getGeoLocation,
    userInfo,
    fetchAllBlock,
    fetchYourCondition,
    fetchSecondLevelCondition,
    fetchMyWellbeing,
    contentSearch,
    searchPractice,
    fetchAllShortenList,
    fetchTelehealth,
    adminLogin /* handle admin login */
});