import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'

// components
import GlobalCTA from '../components/globalComponents/GlobalCTA'
import BackToPrevBtn from '../components/globalComponents/BackToPrevBtn'
import SeoMetaTags from '../components/globalComponents/SeoMetaTags'

//css
import './WhatIsPhysio.sass'

// redux action
import { fetchAllData } from '../redux/actions/fetchWhatIsPhysioAction'
import { hideFullscreenFeatured } from '../redux/actions/fullscreenFeaturedAction'
// icons
import { Share} from '@material-ui/icons'
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// images
import PointerImg from '../components/globalComponents/images/POINTER.png';
import PointerDownImg from '../components/globalComponents/images/POINTER2.png';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share'
import { FacebookIcon, LinkedinIcon } from 'react-share'
import UpdatedTwitterIcon from '../components/globalComponents/UpdatedTwitterIcon';

export default function WhatIsPhysio() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(hideFullscreenFeatured());
        }, [dispatch])
    useEffect(() => {
        dispatch(fetchAllData());
    }, [dispatch]);

    const whatIsPhysioData = useSelector(state => state.fetchWhatIsPhysio.data);
    const pageUrl = window.location.href;
    const Intro = () => {
        return whatIsPhysioData.map(single => 
            <Col md={12} lg={6} key={single.nid}>
                <h1 className="light_primary_heading no_gap">
                    What Is Physio?
                </h1>
                <h2 className="accent_primary_heading left lg" 
                    style={{maxWidth: '500px'}} >
                    {single.title}
                </h2>
                <p>{single.body}</p>
            </Col>
        )
    } 
    
    const [expanded, setExpanded] = React.useState('');
    const handleChange = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
        
    };
    const imageUp = <img alt='icon' src={PointerImg} />;
    const imageDown = <img alt='icon' src={PointerDownImg} />;

    const QuestionList = whatIsPhysioData.map(single => 
        <Row key={single.nid} className="accordion_qa">
            <Col md={12}>
                {single.field_multiple_qustions.map(qustion=>
                    <ExpansionPanel key={qustion.id} square expanded={expanded === 'panel'+qustion.id} onChange={handleChange('panel'+qustion.id)}>
                        <ExpansionPanelSummary className="accordion_label">
                            {expanded==='panel'+qustion.id?imageDown:imageUp}
                            <Typography>{qustion.question} </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography dangerouslySetInnerHTML={{__html:qustion.answers} } />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                )}
            </Col>
        </Row>
    )

    const metaTitle = "What is physio - Choose physio | Australian Physiotherapy Association"
    const metaDescription = "Find a physio. Your GP's most recommended health professional. Choose physio to help you recover from injury, reduce pain and stiffness, increase mobility and prevent further injury."

    return (
      <div id="page_content" class="what-is-physio">
        <SeoMetaTags
          title={metaTitle}
          description={metaDescription}
          twitterImg=""
          ogImg=""
        />

        <div className="site_container">
          {whatIsPhysioData && whatIsPhysioData.length > 0 ? (
            <Row>
              <Intro />

              <Col md={12} lg={6}>
                <div className="iframe_wrapper no_gap">
                  <iframe
                    title="What Is Physio"
                    src="https://www.youtube.com/embed/ormYpdXMJRE"
                    frameBorder="0"
                    allowFullScreen
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col md={12} lg={6}>
                <Skeleton width="40%" height={40} />
                <Skeleton width="60%" height={50} />
                <Skeleton variant="rect" width="100%" height={145} />
              </Col>

              <Col md={12} lg={6}>
                <Skeleton variant="rect" width="100%" height={235} />
              </Col>
            </Row>
          )}

          <Row className="center_content">
            <Col xs={7} md={6}>
              <BackToPrevBtn />
            </Col>

            <Col xs={5} md={6}>
              <div className="share_btn_wrapper">
                <span className="placeHolder">
                  <Share /> Share
                </span>
                <FacebookShareButton url={pageUrl} className="share_btn">
                  <FacebookIcon size={32} round={false} />
                </FacebookShareButton>

                <TwitterShareButton url={pageUrl} className="share_btn">
                    <UpdatedTwitterIcon />
                </TwitterShareButton>

                <LinkedinShareButton url={pageUrl} className="share_btn">
                  <LinkedinIcon size={32} round={false} />
                </LinkedinShareButton>
              </div>
            </Col>
          </Row>

          {whatIsPhysioData && whatIsPhysioData.length > 0 ? (
            QuestionList
          ) : (
            <div className="skeleton">
              <Skeleton width="100%" height={74} />
              <Skeleton width="100%" height={74} />
              <Skeleton width="100%" height={74} />
            </div>
          )}
        </div>

        <GlobalCTA />
      </div>
    );
}