const initialState = {
    visibility: true
}

const WHAT_IS_PHYSIO = "WHAT_IS_PHYSIO";
const GO_BACK = "GO_BACK";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case WHAT_IS_PHYSIO: {
            return {...state, visibility: true}
        }
        case GO_BACK: {
            return {...state, visibility: false}
        }
        default:
            return state;
    }
}