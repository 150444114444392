import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FullscreenFeatured from '../components/physiocantreat/FullscreenFeatured'
import MainGrid from '../components/physiocantreat/MainGrid'
import { CSSTransition } from 'react-transition-group'

import { showContent } from '../redux/actions/fullscreenFeaturedAction'

// components
import SeoMetaTags from '../components/globalComponents/SeoMetaTags'

//css
import './PhysioCanTreat.sass'

export default function PhysioCanTreat({ maintenanceMsg }) {
    const dispatch = useDispatch();

    const showFeatured = useSelector(state => state.fullscreenFeatured.visibility) || maintenanceMsg;
    const loadContent = useSelector(state => state.fullscreenFeatured.showContent);

    const showContentHandler = () => {
        dispatch(showContent());
    }

    useEffect(() => {
        if ( showFeatured ){
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "visible";
        }
        return function cleanup() {
            document.body.style.overflow = "visible";
        }
    }, [showFeatured])

    const metaTitle = "Physio can treat - Choose physio | Australian Physiotherapy Association"
    const metaDescription = "Find a physio. Your GP's most recommended health professional. Choose physio to help you recover from injury, reduce pain and stiffness, increase mobility and prevent further injury."

    return(
        <React.Fragment>
            <SeoMetaTags 
                title={metaTitle} 
                description={metaDescription} 
                twitterImg="" 
                ogImg="" />

            { showFeatured &&
                <div id="fullscreenFeaturedOverlay"></div>
            }

            <div id="page_content" className="physio-can-treat">
                <CSSTransition in={showFeatured} appear={showFeatured} classNames="fade" 
                    timeout={700} unmountOnExit 
                    onExit={() => showContentHandler()} >
                    <FullscreenFeatured maintenanceMsg={maintenanceMsg}/>
                </CSSTransition>

                <CSSTransition in={loadContent} appear={loadContent} classNames="fade" 
                    timeout={700} unmountOnExit >
                    <div className="site_container main_page">
                        <h2 className="accent_primary_heading center">Choose physio for</h2>
                        <MainGrid />
                    </div> 
                </CSSTransition>
            </div>
        </React.Fragment>
    );
}