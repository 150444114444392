import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { isMobile } from 'react-device-detect'
import SeoMetaTags from '../components/globalComponents/SeoMetaTags'

// components
import GlobalCTA from '../components/globalComponents/GlobalCTA'
import BackToPrevBtn from '../components/globalComponents/BackToPrevBtn'
import Skeleton from '@material-ui/lab/Skeleton'


// css
import './TelehealthPage.sass'

import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share'
// redux action
import { fetchTelehealth, clearData } from '../redux/actions/fetchTelehealthAction'
import { hideFullscreenFeatured } from '../redux/actions/fullscreenFeaturedAction'
// icons
import { Share} from '@material-ui/icons'
import { FacebookIcon, LinkedinIcon } from 'react-share'
import UpdatedTwitterIcon from '../components/globalComponents/UpdatedTwitterIcon';
import PointerImg from '../components/globalComponents/images/POINTER.png'
import PointerDownImg from '../components/globalComponents/images/POINTER2.png'

export default function TelehealthPage(props) {
    const [seoMeta, setSeoMeta] = useState({
        metaTitle: '',
        metaDescription: '',
        getMeta: false
    })
    const dispatch = useDispatch()
useEffect(() => {
    dispatch(hideFullscreenFeatured());
    dispatch(fetchTelehealth());

    // clear out state before exit
    return () => {
        dispatch(clearData());
    };
}, [dispatch, props]);

   
    const telehealthResult = useSelector(state => state.fetchTelehealth.telehealthResult)
    const pageUrl = window.location.href

    const [expanded, setExpanded] = React.useState('')

    const handleChange = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }
    const imageUp = <img alt='icon' src={PointerImg} />
    const imageDown = <img alt='icon' src={PointerDownImg} />
    const QuestionList = telehealthResult.map(telehealth => 
        <Row key={telehealth.nid} className="accordion_qa">
            <Col md={12}>
                {telehealth.field_multiple_qustions.map(qustion=>
                    <ExpansionPanel key={qustion.id} square expanded={expanded === 'panel'+qustion.id} onChange={handleChange('panel'+qustion.id)}>
                        <ExpansionPanelSummary>
                            {expanded==='panel'+qustion.id?imageDown:imageUp}
                            <Typography>{qustion.question}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography dangerouslySetInnerHTML={{__html:qustion.answers} } />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                )}
            </Col>
        </Row>
    )
    
    useEffect(() => {
        if( !seoMeta.getMeta ){
            telehealthResult.map(telehealth => {
                setSeoMeta({
                    ...seoMeta,
                    metaTitle: telehealth.title,
                    getMeta: true
                })
                return true
            })
        }
    }, [seoMeta, telehealthResult] )

    const metaTitle = `${seoMeta.metaTitle} - Choose physio | Australian Physiotherapy Association`
    const metaDescription = ""

   

    return(
        <div id="page_content" className="single_condition telehealth-page">
            <SeoMetaTags 
                title={metaTitle} 
                description = {metaDescription}
                />

            <div className="site_container" >
                {telehealthResult && telehealthResult.length > 0 ? (
                    telehealthResult.map(telehealth =>
                        <div className="condition_section" key={telehealth.nid}>
                            <Row>
                                <Col md={12} lg={6} >
                                    <h1 className="light_primary_heading no_gap">
                                        Choose physio
                                    </h1>
                                    <h2 className="accent_primary_heading left lg" 
                                        style={{maxWidth: '500px'}} >
                                        for {telehealth.title}
                                    </h2>
                                    <p>{telehealth.body}</p>
                                </Col>

                                <Col md={12} lg={6} >
                               
                                    <div className="image_wrapper no_gap" style={{backgroundImage: `url(${telehealth.field_physio_help_image})`}} >
                                        { isMobile &&
                                            <img alt={telehealth.title} src={telehealth.field_physio_help_image} />
                                        }
                                    </div>
                                </Col>
                            </Row>

                            <Row className="center_content"> 
                                <Col xs={7} md={6} >
                                    <BackToPrevBtn variant="no_gap" />
                                </Col>

                                <Col xs={5} md={6} >
                                    <div className="share_btn_wrapper">
                                        <span className="placeHolder"><Share /> Share</span>
                                        <FacebookShareButton url={pageUrl} className="share_btn" >
                                            <FacebookIcon size={32} round={false} />
                                        </FacebookShareButton>

                                        <TwitterShareButton url={pageUrl} className="share_btn" >
                                            <UpdatedTwitterIcon />
                                        </TwitterShareButton>
                                        
                                        <LinkedinShareButton url={pageUrl} className="share_btn" >
                                            <LinkedinIcon size={32} round={false} />
                                        </LinkedinShareButton>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )
                ) : (
                    <div className="condition_section">
                        <Row>
                            <Col md={12} lg={6} >
                                <Skeleton width="40%" height={40} />
                                <Skeleton width="60%" height={50}/>
                                <Skeleton variant="rect" width="100%" height={145} />
                            </Col>

                            <Col md={12} lg={6} >
                                <Skeleton variant="rect" width="100%" height={235} />
                            </Col>
                        </Row>
                    </div>
                )}

                { (telehealthResult && telehealthResult.length > 0) ? 
                    (
                        QuestionList
                    ) : (
                        <div className="skeleton">
                            <Skeleton width="100%" height={74} />
                            <Skeleton width="100%" height={74} />
                            <Skeleton width="100%" height={74} />
                        </div>
                    )
                }

                { (telehealthResult && telehealthResult.length > 0) ? 
                    (
                        telehealthResult.map(condition => 
                            condition.field_contribution_list !=="" &&
                            <div className="contribute_section" key={condition.nid}>
                                <Row>
                                    <Col md={12} >
                                        {condition.field_contribution_list}
                                    </Col>
                                </Row>
                            </div> )
                    ) : (
                        <div className="skeleton"><Skeleton width="50%" /></div>
                    )
                }               
            </div>
            <GlobalCTA />
        </div>
    )
}