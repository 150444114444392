const initialState = {
    telehealthResult: []
}

const CLEAR_DATA = "CLEAR_DATA";
const LOAD_TELEHEALTH = "LOAD_TELEHEALTH";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_TELEHEALTH: {
            return {...state,
                telehealthResult: action.payload}
        }
        case CLEAR_DATA: {
            return {
                telehealthResult: []
            }
        }
        default:
            return state;
    }
    
}