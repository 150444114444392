import axios from 'axios'
import { configPara } from '../../components/globalComponents/APIUrlConfig'
const LOAD_CONDITION_THIRD_LEVEL_ITEMS = "LOAD_CONDITION_THIRD_LEVEL_ITEMS";
const CLEAR_STATE = "CLEAR_STATE";
const apiUrl = configPara.url.concat('/api/views/');

const categories = [
    { id: 126, slug: "head" },
    { id: 130, slug: "neck" },
    { id: 128, slug: "chest" },
    { id: 129, slug: "pelvis" },
    { id: 127, slug: "shoulder" },
    { id: 132, slug: "elbow" },
    { id: 133, slug: "wrist" },
    { id: 134, slug: "hand" },
    { id: 135, slug: "fingers" },
    { id: 136, slug: "hip" },
    { id: 137, slug: "leg" },
    { id: 138, slug: "knee" },
    { id: 139, slug: "ankle" },
    { id: 140, slug: "foot" },
    { id: 146, slug: "infants-and-children" },
    { id: 147, slug: "adolescents" },
    { id: 148, slug: "adults" },
    { id: 149, slug: "motherhood" },
    { id: 131, slug: "lower-back" },
    { id: 150, slug: "ageing-well" }
]

export const clearConditionItems = (payload) => {
    return {
        type: CLEAR_STATE,
        payload
    }
}

export const fetchConditionItems = (payload) => {
    return {
        type: LOAD_CONDITION_THIRD_LEVEL_ITEMS,
        payload
    }
};

export const fetchConditionThirdLevelItems = (slug) => {
    const id = categories.find(x => x.slug === slug).id;
    
    return (dispatch) => {
        axios.get(apiUrl+id, {auth: configPara.auth
          })
            .then(response => {
                dispatch(fetchConditionItems(response.data))
            })
            .catch(error => {
                throw(error);
            });
    };
};