const initialState = {
    conditionItems: []
}

const LOAD_CONDITION_THIRD_LEVEL_ITEMS = "LOAD_CONDITION_THIRD_LEVEL_ITEMS";
const CLEAR_STATE = "CLEAR_STATE";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_CONDITION_THIRD_LEVEL_ITEMS: {
            return {...state,
                conditionItems: action.payload}
        }
        case CLEAR_STATE: {
            return {
                conditionItems: []
            }
        }
        default:
            return state;
    }
    
}